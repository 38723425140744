import lookups from "./lookups";
import { CommissionGroupFormStages } from "./commissionGroup";
import { InvoiceFormStages } from "./invoice";

export const OperatorGroupFormStages = [
  {
    title: "Operator Group",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_isallowedtotaketokenisedpayments",
            default: false,
            description: "",
            label: "Allow tokenised payments",
            type: "bit",
          },
          {
            name: "g4b_accountid",
            className: "col-md-4",
            description: "",
            label: "Account",
            lookup: lookups.account.all,
            type: "lookup",
          },
          {
            allowAdd: true,
            allowEdit: true,
            name: "g4b_commissiongroupid",
            description: "",
            label: "Commission Group",
            lookup: lookups.g4b_commissiongroup.all,
            subFormStages: CommissionGroupFormStages,
            type: "lookup",
          },
        ],
      },
      {
        name: "Operators",
        table: {
          allowAdd: true,
          allowEdit: true,
          fields: [
            {
              name: "g4b_pin",
              className: "col-short-name",
              label: "Pin Code",
            },
            {
              name: "g4b_allowdiscounts",
              className: "col-bool",
              label: "Allow Discounts",
            },
            {
              name: "g4b_allowrefunds",
              className: "col-bool",
              label: "Allow Refunds",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          linkedEntityId: "g4b_operatorgroupid",
          linkForNewRecord: "/operator",
          loadStateRelatedEntityName: "g4b_bookingoperator",
          lookup: lookups.g4b_bookingoperator.all,
          useSubForm: false,
        },
      },
      {
        name: "Invoices",
        table: {
          allowEdit: true,
          fields: [
            {
              name: "totalamount",
              className: "col-number",
              type: "money",
              label: "Total amount",
            },
          ],
          filterByStatus: true,
          linkedEntityId: "invoiceid",
          loadStateRelatedEntityName: "invoice",
          lookup: lookups.invoice.all,
          subFormStages: InvoiceFormStages,
          useSubForm: false,
          linkForNewRecord: "/invoice",
        },
      },
    ],
  },
];
