import { Fragment, useState } from "react";
import classNames from "classnames";
import {
  useGlobalReducer,
  useGlobalState,
} from "../../GlobalContext";
import {
  getFieldLabelForFormTable,
  getFilterComponent,
  getLookupFieldOptions,
  getOptionsFromRecords,
  setCreateOrUpdateLookupRecord,
  useRecords,
  SetRecordStatus,
  shouldRenderTableFilters,
  checkForValidRoles,
  displayElement,
} from "./Helpers";
import {
  AlphabetPagination,
  Button,
  EditLink,
  Loading,
  Modal,
  Pagination,
  SetStatusModal,
} from "../elements/_Elements";
import { displayFieldValue } from "../../js/utility";
import { ButtonStyle, RecordStatus } from "../../js/enums";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ArrowDown, ArrowUp, CheckIcon, FilterIcon } from "../Icons";
import AddRecordButton from "./AddRecordButton";
import AppliedFilters from "./AppliedFilters";
import EditStatusButton from "./EditStatusButton";
import Filters from "./Filters";
import RecordsPerPage from "./RecordsPerPage";
import StatusTabs from "./StatusTabs";
import "./FormTable.scss";

function FormTable({
  dispatch,
  disabled = false,
  initialFilters,
  key,
  parentId,
  section,
  setSubForm,
  state,
  subForm,
  subFormParentFormEntityName,
  subFormParentFormState,
  values,
}) {
  const globalDispatch = useGlobalReducer();

  const { table } = section;
  const {
    allowAdd,
    allowAddRoles,
    allowEdit,
    allowStatusEdit,
    fields,
    filterByStatus,
    filterDescription,
    hideNameColumn,
    loadStateRelatedEntityName,
    lookup,
    statusEditAllowedRoles,
    subFormStages,
    useAlphabetPagination,
    useSubForm,
  } = table;

  const globalState = useGlobalState();

  const filteredTableFields = fields.filter((field) =>
    displayElement(field, state, values, globalState)
  );

  //If the table doesn't have a statusEditAllowedRoles value set then allow for
  //any role to edit the status. If it does have a value then check if the logged in
  //user has a valid role which will allow them to edit the record status
  const statusEditAllowed =
    allowStatusEdit &&
    (!statusEditAllowedRoles ||
      (statusEditAllowedRoles &&
        checkForValidRoles(statusEditAllowedRoles, globalState)));

  const entityName = lookup ? lookup.entityName : null;

  const initialOrderedBy =
    typeof table.initialOrderedBy !== "undefined"
      ? table.initialOrderedBy
      : "Name";
  const initialOrderedByAsc =
    typeof table.initialOrderedByAsc !== "undefined"
      ? table.initialOrderedByAsc
      : true;

  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [allRecords, setAllRecords] = useState([]);
  const [letter, setLetter] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRecords, setCurrentRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [recordsSelected, setRecordsSelected] = useState([]);
  const [
    showConfirmStatusChangeModal,
    setShowConfirmStatusChangeModal,
  ] = useState(false);
  const [recordStatusToShow, setRecordStatusToShow] = useState(
    RecordStatus.Active
  );
  const [filters, setFilters] = useState(initialFilters);
  const [
    initialFirstAttemptToGetData,
    setInitialFirstAttemptToGetData,
  ] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [orderedBy, setOrderedBy] = useState(initialOrderedBy);
  const [orderedByAsc, setOrderedByAsc] = useState(
    initialOrderedByAsc
  );
  const [subformAddingNewRecord, setSubformAddingNewRecord] =
    useState(false);
  const [selectedFilter, setSelectedFilter] = useState("");

  // fetch the records on the initial load
  useRecords(
    allRecords,
    entityName,
    filterByStatus,
    filters,
    globalDispatch,
    initialFilters,
    initialFirstAttemptToGetData,
    letter,
    loadStateRelatedEntityName,
    loading,
    orderedBy,
    orderedByAsc,
    recordStatusToShow,
    setAllRecords,
    setCurrentPage,
    setFilteredRecords,
    setFilters,
    setInitialFirstAttemptToGetData,
    setLoading,
    setOrderedBy,
    setOrderedByAsc,
    setSubformAddingNewRecord,
    state,
    subForm,
    subformAddingNewRecord
  );

  const orderRecords = (orderByProperty) => {
    if (orderByProperty === orderedBy) {
      setOrderedByAsc(!orderedByAsc);
    } else {
      setOrderedBy(orderByProperty);
      setOrderedByAsc(true);
    }
  };

  const toggleCheck = (record) => {
    if (recordsSelected.some((m) => m.Id === record.Id)) {
      //Uncheck box
      setRecordsSelected(
        recordsSelected.filter((m) => m.Id !== record.Id)
      );
    } else {
      //Check box
      setRecordsSelected([...recordsSelected, record]);
    }
  };

  const toggleCheckAll = () => {
    if (filteredRecords.length === recordsSelected.length) {
      //Uncheck all
      setRecordsSelected([]);
    } else {
      //Check all
      setRecordsSelected(filteredRecords);
    }
  };

  const handleRecordStatusChange = () => {
    setIsSubmitting(true);
    SetRecordStatus(
      dispatch,
      table.lookup && table.lookup.entityName
        ? table.lookup.entityName
        : entityName,
      recordsSelected.map((r) => r.Id),
      String(recordStatusToShow) === String(RecordStatus.Inactive)
        ? 0
        : 1,
      globalDispatch,
      false,
      setAllRecords,
      setCurrentPage,
      setCurrentRecords,
      setFilteredRecords,
      setInitialFirstAttemptToGetData,
      setRecordsSelected,
      setLoading,
      setIsSubmitting,
      null,
      null,
      null,
      null,
      null,
      state
    );
    setShowConfirmStatusChangeModal(false);
  };

  const setStatus = (status) => {
    setAllRecords([]);
    setCurrentPage(1);
    setCurrentRecords([]);
    setFilteredRecords([]);
    setRecordsSelected([]);
    setRecordStatusToShow(status);
    setLoading(true);
    setInitialFirstAttemptToGetData(true);
  };

  if (loading) {
    return <Loading />;
  }
  /* 
  In the event that we are in a subform, check whether the subFormParentFormState
  has an id value. If it does then its safe to allow for the creation of a new entry
  of the table entity as we don't want it such that we navigate away from the current page
  and lose the values set on the parent form. Otherwise disable the functionality to add and edit records
  and display a message indicating why you can't create a new record 
  */
  const toggleAllowAdd =
    !disabled &&
    allowAdd &&
    (!subFormParentFormState || subFormParentFormState.id) &&
    checkForValidRoles(allowAddRoles, globalState);
  const toggleAllowEdit =
    !disabled &&
    allowEdit &&
    (!subFormParentFormState || subFormParentFormState.id);

  const displayTableFilters = shouldRenderTableFilters(table.filters);
  const tableFiltersWithTypes =
    table.filters && table.filters.length > 0
      ? table.filters.filter((f) => f.type)
      : [];

  const tableFiltersWithTypesToDisplay = tableFiltersWithTypes.filter(
    (f) => !["datetime", "select"].includes(f.type)
  );

  return (
    <>
      {showConfirmStatusChangeModal && (
        <SetStatusModal
          handleRecordStatusChange={handleRecordStatusChange}
          recordStatusToShow={recordStatusToShow}
          setShowConfirmStatusChangeModal={
            setShowConfirmStatusChangeModal
          }
        />
      )}
      {selectedFilter && (
        <Modal
          title={getFieldLabelForFormTable(
            selectedFilter,
            subFormStages
          )}
          modalCloseButtonClick={() => {
            setSelectedFilter("");
          }}
          className="modal"
        >
          <div className="modal-body">
            {getFilterComponent(
              allRecords,
              false,
              tableFiltersWithTypes.find(
                (f) =>
                  ["datetime", "select"].includes(f.type) &&
                  f.name === selectedFilter.name
              ),
              filters,
              setFilters,
              state,
              subFormStages,
              values
            )}
          </div>
        </Modal>
      )}
      {section.name && <h5>{section.name}</h5>}
      {subFormParentFormState && !subFormParentFormState.id && (
        <Fragment key={key}>
          <div className="mb-3">
            <strong>
              {`Please save the current parent ${subFormParentFormEntityName} record to be able to create or update a new record in this table`}
            </strong>
          </div>
        </Fragment>
      )}
      <div className="mb-3">
        {statusEditAllowed && (
          <StatusTabs {...{ recordStatusToShow, setStatus }} />
        )}
        <div className="d-flex mb-3">
          {displayTableFilters && (
            <div className="flex-grow-1">
              <div className="row">
                <Filters
                  {...{
                    allRecords,
                    filters,
                    setFilters,
                    state,
                    subFormStages,
                    values,
                  }}
                  allowExpandFilters={true}
                  displayIcons={true}
                  tableFilters={tableFiltersWithTypesToDisplay}
                />
              </div>
              {filterDescription && (
                <p>
                  <em>{filterDescription}</em>
                </p>
              )}
            </div>
          )}
          {(statusEditAllowed || toggleAllowAdd) && (
            <div className="ms-auto ps-3 align-self-center">
              {statusEditAllowed && (
                <EditStatusButton
                  {...{
                    isSubmitting,
                    recordsSelected,
                    recordStatusToShow,
                    setShowConfirmStatusChangeModal,
                  }}
                />
              )}
              {toggleAllowAdd && (
                <>
                  {useSubForm ? (
                    <Button
                      type={"button"}
                      style={ButtonStyle.Primary}
                      text={"New"}
                      onClick={() => {
                        setCreateOrUpdateLookupRecord(
                          setSubForm,
                          table,
                          null,
                          parentId
                        );
                        setSubformAddingNewRecord(true);
                      }}
                      disabled={isSubmitting}
                    />
                  ) : (
                    <AddRecordButton
                      {...{ filters, parentId, table }}
                    />
                  )}
                </>
              )}
            </div>
          )}
        </div>
        <AppliedFilters
          {...{
            allRecords,
            filters,
            globalState,
            setFilters,
            state,
            values,
          }}
          stages={subFormStages}
        />
        <div className="list-view">
          <div
            className={`table-responsive 
          ${
            tableFiltersWithTypes.some((f) =>
              ["datetime", "select"].includes(f.type)
            )
              ? "table-min-height" //This min height is being set to give some room for the column filter drop downs to be viewable as it gets stuck behind the outside of the table
              : ""
          }`}
          >
            <table className="table table-hover mb-0">
              <thead>
                <tr className="table-info">
                  {statusEditAllowed && (
                    <th
                      className={classNames("col-select")}
                      onClick={() => toggleCheckAll()}
                    >
                      <FontAwesomeIcon
                        className="cursor-pointer"
                        icon={CheckIcon}
                      />
                    </th>
                  )}
                  {!hideNameColumn && (
                    <th
                      scope="col"
                      className={classNames(
                        "col-primary",
                        orderedBy === "Name" ? "ordered" : ""
                      )}
                      onClick={() => orderRecords("Name")}
                    >
                      Name
                      {orderedBy === "Name" ? (
                        orderedByAsc ? (
                          <FontAwesomeIcon
                            className="cursor-pointer ms-2"
                            icon={ArrowUp}
                          />
                        ) : (
                          <FontAwesomeIcon
                            className="cursor-pointer ms-2"
                            icon={ArrowDown}
                          />
                        )
                      ) : (
                        <></>
                      )}
                    </th>
                  )}
                  {filteredTableFields.map((field, i) => {
                    var matchedFilter = tableFiltersWithTypes.find(
                      (f) =>
                        ["datetime", "select"].includes(f.type) &&
                        f.name === field.name
                    );
                    var displayColumnFilter = matchedFilter;

                    if (
                      displayColumnFilter &&
                      matchedFilter.type === "select"
                    ) {
                      //Check if there are options for this select filter
                      const options = matchedFilter.enum
                        ? Object.entries(matchedFilter.enum).map(
                            ([Name, Number]) => ({
                              Key: Number,
                              Value: Name,
                            })
                          )
                        : matchedFilter.lookup
                        ? getLookupFieldOptions(
                            matchedFilter,
                            state,
                            values,
                            null
                          )
                        : allRecords
                        ? getOptionsFromRecords(
                            allRecords,
                            matchedFilter.name
                          )
                        : [];

                      displayColumnFilter =
                        options && options.length > 0;
                    }
                    return (
                      <th
                        key={i}
                        scope="col"
                        className={classNames(
                          field.className,
                          orderedBy === field.name ? "ordered" : ""
                        )}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <span
                            className="me-auto"
                            onClick={() => orderRecords(field.name)}
                          >
                            {getFieldLabelForFormTable(
                              field,
                              subFormStages
                            )}
                            {orderedBy === field.name ? (
                              orderedByAsc ? (
                                <FontAwesomeIcon
                                  className="cursor-pointer ms-2"
                                  icon={ArrowUp}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  className="cursor-pointer ms-2"
                                  icon={ArrowDown}
                                />
                              )
                            ) : (
                              <></>
                            )}
                          </span>
                          {displayColumnFilter && (
                            <span
                              onClick={() => setSelectedFilter(field)}
                            >
                              <FontAwesomeIcon
                                className="cursor-pointer"
                                icon={FilterIcon}
                                size="sm"
                              />
                            </span>
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              {filteredRecords && filteredRecords.length > 0 && (
                <tbody>
                  {currentRecords.map((record, i) => {
                    const highlightRecord =
                      state.highlightRelatedEntities
                        ? state.highlightRelatedEntities.find(
                            (x) =>
                              x.entityName ===
                                loadStateRelatedEntityName &&
                              x.id === record.Id
                          )
                        : false;

                    return (
                      <tr
                        key={i}
                        className={classNames(
                          highlightRecord ? "table-success" : ""
                        )}
                      >
                        {statusEditAllowed && (
                          <td
                            className="text-center"
                            onClick={() => toggleCheck(record)}
                          >
                            <input
                              className="form-check-input cursor-pointer"
                              type="checkbox"
                              name={"record" + i}
                              checked={recordsSelected.some(
                                (m) => m.Id === record.Id
                              )}
                            />
                          </td>
                        )}
                        {!hideNameColumn && (
                          <th
                            scope="row"
                            onClick={() => {
                              toggleAllowEdit && useSubForm
                                ? setCreateOrUpdateLookupRecord(
                                    setSubForm,
                                    table,
                                    record.Id,
                                    parentId
                                  )
                                : console.log(
                                    "Record Cell (1) selected",
                                    toggleAllowEdit
                                  );
                            }}
                          >
                            {toggleAllowEdit &&
                            (useSubForm || table.linkForNewRecord) ? (
                              useSubForm ? (
                                <span className="text-primary text-decoration-underline cursor-pointer">
                                  {record.Name ? record.Name : ""}
                                </span>
                              ) : (
                                <EditLink
                                  id={record.Id}
                                  linkForNewRecord={
                                    table.linkForNewRecord
                                  }
                                  text={record.Name}
                                />
                              )
                            ) : (
                              <span>
                                {record.Name ? record.Name : ""}
                              </span>
                            )}
                          </th>
                        )}
                        {filteredTableFields.map((field, j) => {
                          const type = field.type ? field.type : "";
                          const fieldValue = displayFieldValue(
                            record.Fields[field.name],
                            type,
                            field,
                            record,
                            state
                          );
                          if (
                            j === 0 &&
                            hideNameColumn &&
                            toggleAllowEdit
                          ) {
                            return (
                              <th
                                key={j}
                                scope="row"
                                onClick={() => {
                                  useSubForm
                                    ? setCreateOrUpdateLookupRecord(
                                        setSubForm,
                                        table,
                                        record.Id,
                                        parentId
                                      )
                                    : console.log(
                                        "Record Cell (1) selected",
                                        toggleAllowEdit
                                      );
                                }}
                              >
                                {useSubForm ? (
                                  <span className="text-primary text-decoration-underline cursor-pointer">
                                    {fieldValue}
                                  </span>
                                ) : table.linkForNewRecord ? (
                                  <EditLink
                                    id={record.Id}
                                    linkForNewRecord={
                                      table.linkForNewRecord
                                    }
                                    text={fieldValue}
                                  />
                                ) : (
                                  <span>{fieldValue}</span>
                                )}
                              </th>
                            );
                          } else {
                            let recordId =
                              field.linkedEntityId &&
                              field.linkForNewRecord
                                ? record.Fields[field.linkedEntityId]
                                : null;
                            if (recordId && recordId.Id) {
                              recordId = recordId.Id;
                            }
                            return (
                              <td key={j}>
                                {field.linkedEntityId &&
                                field.linkForNewRecord ? (
                                  <EditLink
                                    id={record.Id}
                                    linkForNewRecord={
                                      table.linkForNewRecord
                                    }
                                    text={fieldValue}
                                  />
                                ) : (
                                  <span>{fieldValue}</span>
                                )}
                              </td>
                            );
                          }
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
            {(!filteredRecords || filteredRecords.length === 0) && (
              <div className="col-12 mt-5">
                <p className="text-center">{"No records found"}</p>
              </div>
            )}
          </div>
          <div className="d-flex align-items-end mt-3">
            <RecordsPerPage
              {...{
                recordsPerPage,
                setRecordsPerPage,
              }}
              records={filteredRecords}
            />
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              records={filteredRecords}
              recordsPerPage={recordsPerPage}
              setRecords={setCurrentRecords}
            />
          </div>
        </div>
      </div>
      {useAlphabetPagination &&
        allRecords &&
        allRecords.length > recordsPerPage && (
          <div className="mt-auto">
            <AlphabetPagination
              currentLetter={letter}
              onSelectLetter={setLetter}
            />
          </div>
        )}
    </>
  );
}

export default FormTable;
