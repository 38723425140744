import { isSameOrLaterDate } from "../js/utility";
import lookups from "./lookups";
import { FutureYearsSelectHeader } from "../components/elements/_Elements";
import ReadOnlyAudienceRulesAndCount from "../components/form/communication/ReadOnlyAudienceRulesAndCount";
import {
  ABTestMetric,
  ABTestPublishedStatus,
  ABTestWinnerType,
  SelectedStartType,
} from "../js/enums";
import ABTestStatistics from "../components/form/abTest/ABTestStatistics";

export const ABTestFormStages = [
  {
    title: "Review",
    id: "review",
    displayEditButtonFromState: (values) => values.statuscode !== 4,
    displayIfFromState: (values) => values.statuscode === 4,
    hideNavigationButtons: (values) => values.statuscode === 4,
    renderStage: false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "statuscode",
            enum: ABTestPublishedStatus,
            hidden: true,
            label: "Status",
            type: "picklist",
          },
          {
            name: "g4c_startdate",
            className: "col-md-4",
            dateFormat: "dd/MM/yyyy HH:mm",
            description: "",
            label: "Start Date",
            type: "datetime",
          },
          {
            name: "g4c_enddate",
            className: "col-md-4",
            dateFormat: "dd/MM/yyyy HH:mm",
            description: "",
            label: "End Date",
            type: "datetime",
          },
          {
            name: "g4c_audienceidname",
            className: "col-md-4",
            label: "Communication Audience",
            type: "nvarchar",
          },
          {
            name: "g4c_winnertype",
            className: "col-md-4",
            label: "Winner Type",
            type: "picklist",
            enum: ABTestWinnerType,
          },
          {
            name: "g4c_testperiodenddate",
            className: "col-md-4",
            dateFormat: "dd/MM/yyyy HH:mm",
            label: "Test Period End Date",
            type: "datetime",
          },
          {
            name: "",
            className: "col-12",
            component: ABTestStatistics,
            displayComponentOnOverview: true,
            type: "component",
          },
        ],
      },
    ],
  },
  {
    title: "AB Test",
    id: "abtest",
    displayEditButtonFromState: (values) => values.statuscode !== 4,
    sections: [
      {
        name: "General",
        fields: [
          {
            name: "g4c_name",
            className: "col-md-6",
            description: "",
            isNameField: true,
            label: "Test Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4c_description",
            className: "col-md-6",
            description: "",
            label: "Test Description",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4c_communicationaid",
            className: "col-md-6",
            description: "",
            label: "Communication A",
            lookup: lookups.g4c_communication.abtest,
            mapOptions: (options, _, values) => {
              //Filter out the communication selected in g4c_communicationbid if there is one selected
              if (values.g4c_communicationbid) {
                return options.filter((o, i) => {
                  return o.Key !== values.g4c_communicationbid;
                });
              } else {
                return options;
              }
            },
            required: true,
            type: "lookup",
          },
          {
            name: "g4c_communicationbid",
            className: "col-md-6",
            description: "",
            label: "Communication B",
            lookup: lookups.g4c_communication.abtest,
            mapOptions: (options, _, values) => {
              //Filter out the communication selected in g4c_communicationaid if there is one selected
              if (values.g4c_communicationaid) {
                return options.filter((o, i) => {
                  return o.Key !== values.g4c_communicationaid;
                });
              } else {
                return options;
              }
            },
            required: true,
            type: "lookup",
          },
          {
            name: "g4c_communicationaidname",
            hidden: true,
            type: "nvarchar",
          },
          {
            name: "g4c_communicationbidname",
            hidden: true,
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Audience",
    id: "audience",
    displayEditButtonFromState: (values) => values.statuscode !== 4,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4c_audienceid",
            description: "",
            label: "Communication Audience",
            lookup: lookups.g4c_communicationaudience.all,
            required: true,
            type: "lookup",
          },
          {
            name: "g4c_audienceidname",
            hidden: true,
            type: "nvarchar",
          },
          {
            name: "",
            className: "col-12",
            component: ReadOnlyAudienceRulesAndCount,
            overviewRender: (state, setFieldValue) => {
              return (
                <ReadOnlyAudienceRulesAndCount
                  setFieldValue={setFieldValue}
                  values={state}
                />
              );
            },
            displayIfFromValues: (values) => values.g4c_audienceid,
            type: "component",
          },
        ],
      },
    ],
  },
  {
    title: "Publish",
    id: "publish",
    displayEditButtonFromState: (values) => values.statuscode !== 4,
    editOnOverviewFromState: (values) => values.statuscode !== 4,
    renderStage: false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4c_testpercentage",
            className: "col-md-6",
            description: "",
            label: "Test Percentage",
            required: true,
            type: "int",
            validate: (value) => {
              const isValid = value > 0 && value <= 100;
              return isValid
                ? ""
                : "You must enter a number between 1 and 100";
            },
          },
          {
            name: "g4c_testperiod",
            className: "col-md-6",
            description: "",
            autoFillValue: () => {
              return 24;
            },
            label: "Test Period (Hours)",
            required: true,
            type: "int",
            validate: (value) => {
              return value >= 24
                ? ""
                : "Test Period must be at least 24 hours";
            },
          },
          {
            name: "g4c_metric",
            className: "col-md-6",
            description:
              "What action should the winner be determined by?",
            label: "Metric",
            required: true,
            type: "picklist",
            enum: ABTestMetric,
          },
          {
            name: "g4c_winnertype",
            className: "col-md-6",
            description: "How should the winner be confirmed?",
            label: "Winner Type",
            required: true,
            type: "picklist",
            enum: ABTestWinnerType,
          },
          {
            name: "g4c_starttype",
            className: "col-12",
            description: "",
            label: "When",
            type: "picklist",
            autoFillValue: () => {
              return SelectedStartType.Immediately;
            },
            enum: SelectedStartType,
            required: true,
          },
          {
            name: "g4c_startdate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy HH:mm",
            description: "",
            label: "Start Date",
            required: true,
            showTimeSelect: true,
            type: "datetime",
            displayIfFromValues: (values) => {
              return (
                values &&
                String(values.g4c_starttype) ===
                  String(SelectedStartType.Scheduled)
              );
            },
          },
          {
            name: "g4c_enddate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy HH:mm",
            description: "",
            filterDate: (date, values) => {
              return String(values.g4c_starttype) ===
                String(SelectedStartType.Scheduled) &&
                values.g4c_startdate
                ? isSameOrLaterDate(
                    date,
                    new Date(values.g4c_startdate)
                  )
                : String(values.g4c_starttype) ===
                  String(SelectedStartType.Immediately)
                ? isSameOrLaterDate(date, new Date())
                : true;
            },
            label: "End Date",
            type: "datetime",
            showTimeSelect: true,
            renderCustomHeader: FutureYearsSelectHeader,
            required: true,
            validate: (date, values) => {
              const isValid =
                String(values.g4c_starttype) ===
                  String(SelectedStartType.Scheduled) &&
                values.g4c_startdate
                  ? isSameOrLaterDate(date, values.g4c_startdate)
                  : String(values.g4c_starttype) ===
                    String(SelectedStartType.Immediately)
                  ? isSameOrLaterDate(date, new Date())
                  : true;
              return isValid ? "" : "Select a later date";
            },
          },
          {
            name: "g4c_expiryurl",
            className: "col-md-6",
            description:
              "Any attempt to access the email after the end date will redirect to the Expiry URL",
            label: "Expiry URL",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
