import { useState } from "react";
import { DndContext, DragOverlay } from "@dnd-kit/core";
import { DesignSection } from "../../elements/_Elements";
import { LoadingState } from "../../../js/enums";
import { handleDragEnd } from "./Helpers";
import Sidebar from "./Sidebar";
import Triggers from "./Triggers";
import "./Designer.scss";

function Designer({
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  state,
  touched,
  values,
}) {
  const [formState, setFormState] = useState({
    activeId: null,
    Loading: LoadingState.NotLoaded,
  });

  const onDragStart = (event) => {
    setFormState({
      ...formState,
      activeId: event.active.id,
    });
  };

  const onDragEnd = (event) => {
    setFormState({
      ...formState,
      activeId: null,
    });
    handleDragEnd(event, setFieldValue, state, values);
  };

  return (
    <DndContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
      <DesignSection
        className={formState.activeId ? "is-dragging" : ""}
      >
        <DesignSection.Content>
          <Triggers
            {...{ errors, setFieldValue, values }}
            activeId={formState.activeId}
          />
        </DesignSection.Content>
        <DesignSection.Sidebar>
          <Sidebar
            {...{
              errors,
              handleBlur,
              handleChange,
              setFieldValue,
              state,
              touched,
              values,
            }}
          />
        </DesignSection.Sidebar>
      </DesignSection>
      <DragOverlay>
        {formState.activeId ? <div id="drag-overlay" /> : null}
      </DragOverlay>
    </DndContext>
  );
}

export default Designer;
