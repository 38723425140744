import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { GlobalProvider } from "./GlobalContext";
import FormPage from "./FormPage";
import Home from "./Home";
import NotFound from "./NotFound";
import Reporting from "./Reporting";
import {
  ABTestFormStages,
  ABTestsFormStages,
  AccessControlProviderFormStages,
  AccessControlProvidersFormStages,
  AccessPointFormStages,
  AccessPointsFormStages,
  AccountFormStages,
  AccountsFormStages,
  AdditionalProductFormStages,
  AdditionalProductsFormStages,
  AdminSettingsFormStages,
  AnswerGroupFormStages,
  AnswerGroupOptionFormStages,
  AnswerOptionFormStages,
  AnswerOptionCategoryFormStages,
  BlacklistFormStages,
  BlockFormStages,
  BlocksFormStages,
  BlockSeatFormStages,
  BookableResourceFormStages,
  BookableResourcesFormStages,
  BookingFormStages,
  BookingsFormStages,
  BookingTicketFormStages,
  CalendarItemFormStages,
  CampaignFormStages,
  CampaignsFormStages,
  ChannelFormStages,
  ChannelsFormStages,
  ChannelPriceListFormStages,
  ChannelPriceListsFormStages,
  ChannelQuestionFormStages,
  CharityFormStages,
  CitiesFormStages,
  CityFormStages,
  CommissionCategoryFormStages,
  CommissionCategoriesFormStages,
  CommissionGroupFormStages,
  CommissionGroupsFormStages,
  CommissionRateFormStages,
  CommissionRatesFormStages,
  CommunicationFormStages,
  CommunicationsFormStages,
  CommunicationActivitiesFormStages,
  CommunicationActivityFormStages,
  CommunicationAudienceFormStages,
  CommunicationAudiencesFormStages,
  CommunicationFlowFormStages,
  CommunicationFlowsFormStages,
  CommunicationSettingsFormStages,
  ContactFormStages,
  ContactsFormStages,
  CountriesFormStages,
  CountryFormStages,
  CouponFormStages,
  CouponsFormStages,
  CouponProductFormStages,
  CouponTypeFormStages,
  CouponTypesFormStages,
  CrossSellProductFormStages,
  CustomerAddressFormStages,
  DonationBatchHeaderFormStages,
  DonationBatchHeadersFormStages,
  FixtureFormStages,
  FixturesFormStages,
  GlobalSettingsFormStages,
  GroupFormStages,
  GroupsFormStages,
  InvoiceFormStages,
  InvoiceBatchHeadersFormStages,
  InvoiceBatchHeaderFormStages,
  LanguageFormStages,
  MarketingListFormStages,
  MarketingListsFormStages,
  MembershipFormStages,
  MembershipAccessTokenFormStages,
  MembershipAccountFormStages,
  MembershipAccountsFormStages,
  MembershipPaymentPlanFormStages,
  OnHoldAuditFormStages,
  OnHoldReasonFormStages,
  OnHoldReasonsFormStages,
  OperatorFormStages,
  OperatorGroupFormStages,
  OperatorGroupsFormStages,
  OperatorsFormStages,
  PaymentBatchFormStages,
  PaymentBatchesFormStages,
  PaymentBatchItemsFormStages,
  PaymentMethodFormStages,
  PaymentMethodsFormStages,
  PriceBreakFormStages,
  PriceBreakGroupFormStages,
  PriceBreakGroupsFormStages,
  PrinterFormStages,
  PrintersFormStages,
  PrintTransformFormStages,
  PrintTransformsFormStages,
  ProductCalendarFormStages,
  ProductCalendarsFormStages,
  ProductCategoryFormStages,
  ProductCategoriesFormStages,
  ProductFormStages,
  ProductsFormStages,
  ProductModifierFormStages,
  PromotionFormStages,
  PromotionsFormStages,
  PromotionExclusionDateFormStages,
  PromotionExclusionDatesFormStages,
  PromotionProductGroupFormStages,
  PromotionProductGroupsFormStages,
  PromotionTypeFormStages,
  PromotionTypesFormStages,
  QuestionFormStages,
  QuestionsFormStages,
  RegionFormStages,
  RegionsFormStages,
  SalesPlanFormStages,
  SalesPlansFormStages,
  SeatAllocationFormStages,
  SeatClassFormStages,
  SeatClassesFormStages,
  SerieFormStages,
  SeriesFormStages,
  SubscriptionGroupFormStages,
  SubscriptionGroupsFormStages,
  TaxCodeFormStages,
  TaxCodesFormStages,
  TaxComponentFormStages,
  TaxRateFormStages,
  TelegramQueueFormStages,
  TerminalFormStages,
  TerminalsFormStages,
  TillGroupFormStages,
  TillGroupsFormStages,
  VariantPriceListFormStages,
  VariantPriceListsFormStages,
  VariantTypeFormStages,
  VariantTypesFormStages,
  VenueFormStages,
  VenuesFormStages,
  VenueConfigurationFormStages,
  VenueConfigurationBlockFormStages,
  VoucherFormStages,
  VouchersFormStages,
  ZoneFormStages,
  ZonesFormStages,
} from "./forms";
import "./scss/main.scss";
import { ReportType } from "./js/enums";
import {
  GetAdminSettings,
  GetCommunicationOptions,
  GetGlobalSettings,
} from "./js/service";
import useSitemapStages from "./forms/sitemap";
import useQuickstartStages from "./forms/quickStart";
import { getPageAllowedRoles } from "./js/roles";
import ChannelPriceListCopy from "./components/form/channelPriceList/ChannelPriceListCopy";
import ProductCopy from "./components/form/product/ProductCopy";
import JournalDailyReports from "./components/form/dailyReports/JournalDailyReports";
import BlacklistPageHeader from "./components/form/blacklist/BlacklistPageHeader";
import BlacklistAudits from "./components/form/blacklist/Audits";

const ABTest = () => (
  <FormPage
    closeRedirect="/abtests"
    entityName="g4c_abtest"
    showInfo={true}
    stages={ABTestFormStages}
    allowedRoles={getPageAllowedRoles("abtest")}
    title="A/B Test"
  />
);

const ABTests = () => (
  <FormPage
    stages={ABTestsFormStages}
    allowedRoles={getPageAllowedRoles("abtest")}
    title="A/B Tests"
  />
);

const AccessControlProvider = () => (
  <FormPage
    closeRedirect="/access-control-providers"
    entityName="g4b_accesscontrolprovider"
    showInfo={true}
    stages={AccessControlProviderFormStages}
    allowedRoles={getPageAllowedRoles("access-control-provider")}
    title="Access Control Provider"
  />
);

const AccessControlProviders = () => (
  <FormPage
    stages={AccessControlProvidersFormStages}
    allowedRoles={getPageAllowedRoles("access-control-provider")}
    title="Access Control Providers"
  />
);

const AccessPoint = () => (
  <FormPage
    closeRedirect="/access-points"
    entityName="g4b_accesspoint"
    showInfo={true}
    stages={AccessPointFormStages}
    allowedRoles={getPageAllowedRoles("access-point")}
    title="Access Point"
  />
);

const AccessPoints = () => (
  <FormPage
    stages={AccessPointsFormStages}
    allowedRoles={getPageAllowedRoles("access-point")}
    title="Access Points"
  />
);

const Account = () => (
  <FormPage
    closeRedirect="/accounts"
    entityName="account"
    stages={AccountFormStages}
    allowedRoles={getPageAllowedRoles("account")}
    title="Account"
  />
);

const Accounts = () => (
  <FormPage
    stages={AccountsFormStages}
    allowedRoles={getPageAllowedRoles("account")}
    title="Accounts"
  />
);

const AdditionalProduct = () => (
  <FormPage
    closeRedirect="/additional-products"
    entityName="g4b_additionalproduct"
    showInfo={true}
    stages={AdditionalProductFormStages}
    allowedRoles={getPageAllowedRoles("additional-product")}
    title="Additional Product"
  />
);

const AdditionalProducts = () => (
  <FormPage
    stages={AdditionalProductsFormStages}
    allowedRoles={getPageAllowedRoles("additional-product")}
    title="Additional Products"
  />
);

const AdminSettings = () => (
  <FormPage
    auditLabel="adminsettings"
    entityName="g4b_companydetails"
    showInfo={true}
    stages={AdminSettingsFormStages}
    loadDataMethod={GetAdminSettings}
    methodName="ticketing/updateadminsettings"
    allowedRoles={getPageAllowedRoles("admin-settings")}
    title="Admin Settings"
  />
);

const AnswerGroup = () => (
  <FormPage
    entityName="g4_answergroup"
    showInfo={true}
    stages={AnswerGroupFormStages}
    allowedRoles={getPageAllowedRoles("answer-group")}
    title="Answer Group"
  />
);

const AnswerGroupOption = () => (
  <FormPage
    entityName="g4_answergroupoption"
    showInfo={true}
    stages={AnswerGroupOptionFormStages}
    allowedRoles={getPageAllowedRoles("answer-group-option")}
    title="Answer Group Option"
  />
);

const AnswerOption = () => (
  <FormPage
    entityName="g4_answeroption"
    showInfo={true}
    stages={AnswerOptionFormStages}
    allowedRoles={getPageAllowedRoles("answer-option")}
    title="Answer Option"
  />
);

const AnswerOptionCategory = () => (
  <FormPage
    entityName="g4_answeroptioncategory"
    showInfo={true}
    stages={AnswerOptionCategoryFormStages}
    allowedRoles={getPageAllowedRoles("answer-option-category")}
    title="Answer Option Category"
  />
);

const Blacklist = () => (
  <FormPage
    stages={BlacklistFormStages}
    headerComponent={BlacklistPageHeader}
    informationComponent={BlacklistAudits}
    allowedRoles={getPageAllowedRoles("blacklist")}
    title="Blacklist"
  />
);

const Block = () => (
  <FormPage
    entityName="g4b_block"
    showInfo={true}
    stages={BlockFormStages}
    allowedRoles={getPageAllowedRoles("block")}
    title="Block"
  />
);

const Blocks = () => (
  <FormPage
    stages={BlocksFormStages}
    allowedRoles={getPageAllowedRoles("block")}
    title="Blocks"
  />
);

const BlockSeat = () => (
  <FormPage
    entityName="g4b_blockseat"
    showInfo={true}
    stages={BlockSeatFormStages}
    allowedRoles={getPageAllowedRoles("block-seat")}
    title="Block Seat"
  />
);

const BookableResource = () => (
  <FormPage
    closeRedirect="/bookable-resources"
    entityName="g4b_bookableresource"
    showInfo={true}
    stages={BookableResourceFormStages}
    allowedRoles={getPageAllowedRoles("bookable-resource")}
    title="Bookable Resource"
  />
);

const BookableResources = () => (
  <FormPage
    stages={BookableResourcesFormStages}
    allowedRoles={getPageAllowedRoles("bookable-resource")}
    title="Bookable Resources"
  />
);

const Booking = () => (
  <FormPage
    closeRedirect="/bookings"
    entityName="g4b_booking"
    stages={BookingFormStages}
    allowedRoles={getPageAllowedRoles("booking")}
    title="Booking"
  />
);

const Bookings = () => (
  <FormPage
    stages={BookingsFormStages}
    allowedRoles={getPageAllowedRoles("booking")}
    title="Bookings"
  />
);

const BookingTicket = () => (
  <FormPage
    entityName="g4b_ticket"
    stages={BookingTicketFormStages}
    allowedRoles={getPageAllowedRoles("booking-ticket")}
    title="Booking Ticket"
  />
);

const CalendarItem = () => (
  <FormPage
    entityName="g4b_calendaritem"
    showInfo={true}
    stages={CalendarItemFormStages}
    allowedRoles={getPageAllowedRoles("calendar-item")}
    title="Calendar Item"
  />
);

const Campaign = () => (
  <FormPage
    closeRedirect="/campaigns"
    entityName="campaign"
    showInfo={true}
    stages={CampaignFormStages}
    allowedRoles={getPageAllowedRoles("campaign")}
    title="Campaign"
  />
);

const Campaigns = () => (
  <FormPage
    stages={CampaignsFormStages}
    allowedRoles={getPageAllowedRoles("campaign")}
    title="Campaigns"
  />
);

const Channel = () => (
  <FormPage
    closeRedirect="/channels"
    entityName="g4b_channel"
    showInfo={true}
    stages={ChannelFormStages}
    allowedRoles={getPageAllowedRoles("channel")}
    title="Channel"
  />
);

const Channels = () => (
  <FormPage
    stages={ChannelsFormStages}
    allowedRoles={getPageAllowedRoles("channel")}
    title="Channels"
  />
);

const ChannelPriceList = () => (
  <FormPage
    closeRedirect="/channel-price-lists"
    saveAndNewRedirect="/channel-price-list"
    entityName="g4b_channelpricelist"
    showInfo={true}
    stages={ChannelPriceListFormStages}
    showSaveAndNewButton={true}
    showCopyButton={true}
    customCopyComponent={ChannelPriceListCopy}
    allowedRoles={getPageAllowedRoles("channel-price-list")}
    title="Channel Price List"
  />
);
const ChannelPriceLists = () => (
  <FormPage
    showInfo={true}
    stages={ChannelPriceListsFormStages}
    allowedRoles={getPageAllowedRoles("channel-price-list")}
    title="Channel Price Lists"
  />
);

const ChannelQuestion = () => (
  <FormPage
    entityName="g4b_channelquestion"
    showInfo={true}
    stages={ChannelQuestionFormStages}
    allowedRoles={getPageAllowedRoles("channel-question")}
    title="Channel Question"
  />
);

const Charity = () => (
  <FormPage
    closeRedirect="/global-settings"
    entityName="g4d_charity"
    showInfo={true}
    stages={CharityFormStages}
    allowedRoles={getPageAllowedRoles("charity")}
    title="Charity"
  />
);

const Cities = () => (
  <FormPage
    stages={CitiesFormStages}
    allowedRoles={getPageAllowedRoles("city")}
    title="Cities"
  />
);

const City = () => (
  <FormPage
    closeRedirect="/cities"
    entityName="g4_city"
    showInfo={true}
    stages={CityFormStages}
    allowedRoles={getPageAllowedRoles("city")}
    title="City"
  />
);

const Communication = () => (
  <FormPage
    closeRedirect="/communications"
    entityName="g4c_communication"
    showInfo={true}
    stages={CommunicationFormStages}
    allowedRoles={getPageAllowedRoles("communication")}
    title="Communication"
  />
);

const CommissionCategory = () => (
  <FormPage
    closeRedirect="/commission-categories"
    entityName="g4b_commissioncategory"
    showInfo={true}
    stages={CommissionCategoryFormStages}
    allowedRoles={getPageAllowedRoles("commission-category")}
    title="Commission Category"
  />
);

const CommissionCategories = () => (
  <FormPage
    stages={CommissionCategoriesFormStages}
    allowedRoles={getPageAllowedRoles("commission-category")}
    title="Commission Categories"
  />
);

const CommissionGroup = () => (
  <FormPage
    closeRedirect="/commission-groups"
    entityName="g4b_commissiongroup"
    showInfo={true}
    stages={CommissionGroupFormStages}
    allowedRoles={getPageAllowedRoles("commission-group")}
    title="Commission Group"
  />
);

const CommissionGroups = () => (
  <FormPage
    stages={CommissionGroupsFormStages}
    allowedRoles={getPageAllowedRoles("commission-group")}
    title="Commission Groups"
  />
);

const CommissionRate = () => (
  <FormPage
    closeRedirect="/commission-rates"
    entityName="g4b_commissionrate"
    showInfo={true}
    stages={CommissionRateFormStages}
    allowedRoles={getPageAllowedRoles("commission-rate")}
    title="Commission Rate"
  />
);

const CommissionRates = () => (
  <FormPage
    stages={CommissionRatesFormStages}
    allowedRoles={getPageAllowedRoles("commission-rate")}
    title="Commission Rates"
  />
);

const Communications = () => (
  <FormPage
    stages={CommunicationsFormStages}
    allowedRoles={getPageAllowedRoles("communication")}
    title="Communications"
  />
);

const CommunicationActivity = () => (
  <FormPage
    closeRedirect="/communication-activities"
    entityName="g4c_communicationactivity"
    stages={CommunicationActivityFormStages}
    allowedRoles={getPageAllowedRoles("communication-activity")}
  />
);

const CommunicationActivities = () => (
  <FormPage
    stages={CommunicationActivitiesFormStages}
    allowedRoles={getPageAllowedRoles("communication-activity")}
  />
);

const CommunicationAudience = () => (
  <FormPage
    closeRedirect="/communication-audiences"
    entityName="g4c_communicationaudience"
    showInfo={true}
    stages={CommunicationAudienceFormStages}
    allowedRoles={getPageAllowedRoles("communication-audience")}
    title="Communication Audience"
  />
);

const CommunicationAudiences = () => (
  <FormPage
    stages={CommunicationAudiencesFormStages}
    allowedRoles={getPageAllowedRoles("communication-audience")}
    title="Communication Audiences"
  />
);

const CommunicationFlow = () => (
  <FormPage
    closeRedirect="/communication-flows"
    entityName="g4c_triggerflow"
    showInfo={true}
    stages={CommunicationFlowFormStages}
    allowedRoles={getPageAllowedRoles("communication-flow")}
    title="Communication Flow"
  />
);

const CommunicationFlows = () => (
  <FormPage
    stages={CommunicationFlowsFormStages}
    allowedRoles={getPageAllowedRoles("communication-flow")}
    title="Communication Flows"
  />
);

const CommunicationSettings = () => (
  <FormPage
    entityName="g4c_communicationoptions"
    showInfo={true}
    stages={CommunicationSettingsFormStages}
    loadDataMethod={GetCommunicationOptions}
    allowedRoles={getPageAllowedRoles("communication-settings")}
    title="Communication Settings"
  />
);

const Contact = () => (
  <FormPage
    closeRedirect="/contacts"
    entityName="contact"
    showInfo={true}
    stages={ContactFormStages}
    allowedRoles={getPageAllowedRoles("contact")}
    title="Contact"
  />
);

const Contacts = () => (
  <FormPage
    stages={ContactsFormStages}
    allowedRoles={getPageAllowedRoles("contact")}
    title="Contacts"
  />
);

const ContactsReport = () => (
  <Reporting
    reportType={ReportType.Contacts}
    allowedRoles={getPageAllowedRoles("contacts-report")}
    title="Contacts Report"
  />
);

const Countries = () => (
  <FormPage
    stages={CountriesFormStages}
    allowedRoles={getPageAllowedRoles("country")}
    title="Countries"
  />
);

const Country = () => (
  <FormPage
    closeRedirect="/countries"
    entityName="g4_country"
    showInfo={true}
    stages={CountryFormStages}
    allowedRoles={getPageAllowedRoles("country")}
    title="Country"
  />
);

const Coupon = () => (
  <FormPage
    closeRedirect="/coupons"
    entityName="g4b_coupon"
    stages={CouponFormStages}
    allowedRoles={getPageAllowedRoles("coupon")}
    title="Coupon"
  />
);

const Coupons = () => (
  <FormPage
    stages={CouponsFormStages}
    allowedRoles={getPageAllowedRoles("coupon")}
    title="Coupons"
  />
);

const CouponProduct = () => (
  <FormPage
    entityName="g4b_couponproduct"
    showInfo={true}
    stages={CouponProductFormStages}
    allowedRoles={getPageAllowedRoles("coupon-product")}
    title="Coupon Product"
  />
);

const CouponType = () => (
  <FormPage
    closeRedirect="/coupon-types"
    entityName="g4b_coupontype"
    showInfo={true}
    stages={CouponTypeFormStages}
    allowedRoles={getPageAllowedRoles("coupon-type")}
    title="Coupon Type"
  />
);

const CouponTypes = () => (
  <FormPage
    stages={CouponTypesFormStages}
    allowedRoles={getPageAllowedRoles("coupon-type")}
    title="Coupon Types"
  />
);

const CrossSell = () => (
  <FormPage
    entityName="g4b_crosssellproduct"
    showInfo={true}
    stages={CrossSellProductFormStages}
    allowedRoles={getPageAllowedRoles("cross-sell")}
    title="Cross Sell"
  />
);

const CustomerAddress = () => (
  <FormPage
    entityName="customeraddress"
    stages={CustomerAddressFormStages}
    allowedRoles={getPageAllowedRoles("customer-address")}
    title="Customer Address"
  />
);

const DonationBatchHeader = () => (
  <FormPage
    closeRedirect="/donation-batch-headers"
    entityName="g4d_donationbatchheader"
    stages={DonationBatchHeaderFormStages}
    allowedRoles={getPageAllowedRoles("donation-batch-header")}
    title="Donation Batch Header"
  />
);

const DonationBatchHeaders = () => (
  <FormPage
    stages={DonationBatchHeadersFormStages}
    allowedRoles={getPageAllowedRoles("donation-batch-header")}
    title="Donation Batch Headers"
  />
);

const EmailMarketingReport = () => (
  <Reporting
    reportType={ReportType.EmailMarketing}
    allowedRoles={getPageAllowedRoles("email-marketing-report")}
    title="Email Marketing Report"
  />
);

const FinancialReport = () => (
  <Reporting
    reportType={ReportType.Financial}
    allowedRoles={getPageAllowedRoles("financial-report")}
    title="Financial Report"
  />
);

const Fixture = () => (
  <FormPage
    closeRedirect="/fixtures"
    entityName="g4b_fixture"
    showInfo={true}
    stages={FixtureFormStages}
    allowedRoles={getPageAllowedRoles("fixture")}
    title="Fixture"
  />
);

const Fixtures = () => (
  <FormPage
    stages={FixturesFormStages}
    allowedRoles={getPageAllowedRoles("fixture")}
    title="Fixtures"
  />
);

const GlobalSettings = () => (
  <FormPage
    auditLabel="globalsettings"
    entityName="g4b_companydetails"
    showInfo={true}
    stages={GlobalSettingsFormStages}
    loadDataMethod={GetGlobalSettings}
    methodName="ticketing/updateglobalsettings"
    allowedRoles={getPageAllowedRoles("global-settings")}
    title="Global Settings"
  />
);

const Group = () => (
  <FormPage
    closeRedirect="/groups"
    entityName="g4_group"
    showInfo={true}
    stages={GroupFormStages}
    allowedRoles={getPageAllowedRoles("group")}
    title="Group"
  />
);

const Groups = () => (
  <FormPage
    stages={GroupsFormStages}
    allowedRoles={getPageAllowedRoles("group")}
    title="Groups"
  />
);

const Invoice = () => (
  <FormPage
    entityName="invoice"
    showInfo={true}
    stages={InvoiceFormStages}
    allowedRoles={getPageAllowedRoles("invoice")}
    title="Invoice"
  />
);

const InvoiceBatchHeaders = () => (
  <FormPage
    stages={InvoiceBatchHeadersFormStages}
    allowedRoles={getPageAllowedRoles("invoice-batch-header")}
    title="Invoice Batch Headers"
  />
);

const InvoiceBatchHeader = () => (
  <FormPage
    closeRedirect="/invoice-batch-headers"
    entityName="g4b_invoicebatchheader"
    showInfo={true}
    stages={InvoiceBatchHeaderFormStages}
    allowedRoles={getPageAllowedRoles("invoice-batch-header")}
    title="Invoice Batch Header"
  />
);

const JournalDailyReportsPage = () => (
  <JournalDailyReports
    allowedRoles={getPageAllowedRoles("journal-daily-reports")}
    title="Journal Daily Reports"
  />
);

const Language = () => (
  <FormPage
    entityName="g4_language"
    showInfo={true}
    stages={LanguageFormStages}
    allowedRoles={getPageAllowedRoles("language")}
    title="Language"
  />
);

const MarketingList = () => (
  <FormPage
    closeRedirect="/marketing-lists"
    entityName="list"
    stages={MarketingListFormStages}
    allowedRoles={getPageAllowedRoles("marketing-list")}
    title="Marketing List"
  />
);

const MarketingLists = () => (
  <FormPage
    stages={MarketingListsFormStages}
    allowedRoles={getPageAllowedRoles("marketing-list")}
    title="Marketing Lists"
  />
);

const Membership = () => (
  <FormPage
    entityName="g4m_membership"
    stages={MembershipFormStages}
    allowedRoles={getPageAllowedRoles("membership")}
    title="Membership"
  />
);

const MembershipAccessToken = () => (
  <FormPage
    entityName="g4m_membershipaccesstoken"
    stages={MembershipAccessTokenFormStages}
    allowedRoles={getPageAllowedRoles("membership-access-token")}
    title="Membership Access Token"
  />
);

const MembershipAccount = () => (
  <FormPage
    entityName="g4m_membershipaccount"
    stages={MembershipAccountFormStages}
    allowedRoles={getPageAllowedRoles("membership-account")}
    title="Membership Account"
  />
);

const MembershipAccounts = () => (
  <FormPage
    stages={MembershipAccountsFormStages}
    allowedRoles={getPageAllowedRoles("membership-account")}
    title="Membership Accounts"
  />
);

const MembershipPaymentPlan = () => (
  <FormPage
    entityName="g4m_membershippaymentplan"
    showInfo={true}
    stages={MembershipPaymentPlanFormStages}
    allowedRoles={getPageAllowedRoles("membership")}
    title="Membership Payment Plan"
  />
);

const MembershipsReport = () => (
  <Reporting
    reportType={ReportType.Memberships}
    allowedRoles={getPageAllowedRoles("memberships-report")}
    title="Membership Report"
  />
);

const OperationalReport = () => (
  <Reporting
    reportType={ReportType.Operational}
    allowedRoles={getPageAllowedRoles("operational-report")}
    title="Operational Report"
  />
);

const OnHoldAudit = () => (
  <FormPage
    entityName="g4b_onholdaudit"
    refreshPageUponSave={true}
    showInfo={true}
    stages={OnHoldAuditFormStages}
    allowedRoles={getPageAllowedRoles("on-hold-audit")}
    title="On Hold Audit"
  />
);

const OnHoldReason = () => (
  <FormPage
    closeRedirect="/on-hold-reasons"
    entityName="g4b_onholdreason"
    showInfo={true}
    stages={OnHoldReasonFormStages}
    allowedRoles={getPageAllowedRoles("on-hold-reason")}
    title="On Hold Reason"
  />
);

const OnHoldReasons = () => (
  <FormPage
    stages={OnHoldReasonsFormStages}
    allowedRoles={getPageAllowedRoles("on-hold-reason")}
    title="On Hold Reasons"
  />
);

const Operator = () => (
  <FormPage
    closeRedirect="/operators"
    entityName="g4b_bookingoperator"
    showInfo={true}
    stages={OperatorFormStages}
    allowedRoles={getPageAllowedRoles("operator")}
    title="Operator"
  />
);

const OperatorGroup = () => (
  <FormPage
    closeRedirect="/operator-groups"
    entityName="g4b_operatorgroup"
    showInfo={true}
    stages={OperatorGroupFormStages}
    allowedRoles={getPageAllowedRoles("operator-group")}
    title="Operator Group"
  />
);

const OperatorGroups = () => (
  <FormPage
    stages={OperatorGroupsFormStages}
    allowedRoles={getPageAllowedRoles("operator-group")}
    title="Operator Groups"
  />
);

const Operators = () => (
  <FormPage
    stages={OperatorsFormStages}
    allowedRoles={getPageAllowedRoles("operator")}
    title="Operators"
  />
);

const PaymentBatch = () => (
  <FormPage
    closeRedirect="/payment-batches"
    entityName="g4b_paymentbatch"
    showInfo={true}
    stages={PaymentBatchFormStages}
    allowedRoles={getPageAllowedRoles("payment-batch")}
    title="Payment Batch"
  />
);

const PaymentBatches = () => (
  <FormPage
    stages={PaymentBatchesFormStages}
    allowedRoles={getPageAllowedRoles("payment-batch")}
    title="Payment Batches"
  />
);

const PaymentBatchItems = () => (
  <FormPage
    closeRedirect="/payment-batch-items"
    entityName="g4b_paymentbatchitem"
    stages={PaymentBatchItemsFormStages}
    allowedRoles={getPageAllowedRoles("payment-batch-item")}
    title="Payment Batch Items"
  />
);

const PaymentMethod = () => (
  <FormPage
    closeRedirect="/payment-methods"
    entityName="g4b_paymentmethod"
    showInfo={true}
    stages={PaymentMethodFormStages}
    allowedRoles={getPageAllowedRoles("payment-method")}
    title="Payment Method"
  />
);

const PaymentMethods = () => (
  <FormPage
    stages={PaymentMethodsFormStages}
    allowedRoles={getPageAllowedRoles("payment-method")}
    title="Payment Methods"
  />
);

const PriceBreak = () => (
  <FormPage
    entityName="g4b_pricebreak"
    showInfo={true}
    stages={PriceBreakFormStages}
    allowedRoles={getPageAllowedRoles("price-break")}
    title="Price Break"
  />
);

const PriceBreakGroup = () => (
  <FormPage
    closeRedirect="/price-break-groups"
    entityName="g4b_pricebreakgroup"
    showInfo={true}
    stages={PriceBreakGroupFormStages}
    allowedRoles={getPageAllowedRoles("price-break-group")}
    title="Price Break Group"
  />
);

const PriceBreakGroups = () => (
  <FormPage
    stages={PriceBreakGroupsFormStages}
    allowedRoles={getPageAllowedRoles("price-break-group")}
    title="Price Break Groups"
  />
);

const Printer = () => (
  <FormPage
    closeRedirect="/printers"
    entityName="g4b_printer"
    showInfo={true}
    stages={PrinterFormStages}
    allowedRoles={getPageAllowedRoles("printer")}
    title="Printer"
  />
);

const Printers = () => (
  <FormPage
    stages={PrintersFormStages}
    allowedRoles={getPageAllowedRoles("printer")}
    title="Printers"
  />
);

const PrintTransform = () => (
  <FormPage
    closeRedirect="/print-transforms"
    entityName="g4b_printtransform"
    showInfo={true}
    stages={PrintTransformFormStages}
    allowedRoles={getPageAllowedRoles("print-transform")}
    title="Print Transform"
  />
);

const PrintTransforms = () => (
  <FormPage
    stages={PrintTransformsFormStages}
    allowedRoles={getPageAllowedRoles("print-transform")}
    title="Print Transforms"
  />
);

const Product = () => (
  <FormPage
    closeRedirect="/products"
    entityName="g4b_productbase"
    showInfo={true}
    stages={ProductFormStages}
    showCopyButton={true}
    customCopyComponent={ProductCopy}
    allowedRoles={getPageAllowedRoles("product")}
    title="Product"
  />
);

const Products = () => (
  <FormPage
    stages={ProductsFormStages}
    allowedRoles={getPageAllowedRoles("product")}
    title="Products"
  />
);

const ProductCalendar = () => (
  <FormPage
    closeRedirect="/product-calendars"
    entityName="g4b_productcalendar"
    showInfo={true}
    stages={ProductCalendarFormStages}
    allowedRoles={getPageAllowedRoles("product-calendar")}
    title="Product Calendar"
  />
);

const ProductCalendars = () => (
  <FormPage
    stages={ProductCalendarsFormStages}
    allowedRoles={getPageAllowedRoles("product-calendar")}
    title="Product Calendars"
  />
);

const ProductCategory = () => (
  <FormPage
    closeRedirect="/product-categories"
    entityName="g4b_analysiscategory"
    showInfo={true}
    stages={ProductCategoryFormStages}
    allowedRoles={getPageAllowedRoles("product-category")}
    title="Product Category"
  />
);

const ProductCategories = () => (
  <FormPage
    stages={ProductCategoriesFormStages}
    allowedRoles={getPageAllowedRoles("product-category")}
    title="Product Categories"
  />
);

const ProductModifier = () => (
  <FormPage
    entityName="g4b_productbasemodifier"
    showInfo={true}
    stages={ProductModifierFormStages}
    allowedRoles={getPageAllowedRoles("product-modifier")}
    title="Product Modifier"
  />
);

const Promotion = () => (
  <FormPage
    closeRedirect="/promotions"
    entityName="g4b_promotion"
    showInfo={true}
    stages={PromotionFormStages}
    allowedRoles={getPageAllowedRoles("promotion")}
    title="Promotion"
  />
);

const Promotions = () => (
  <FormPage
    stages={PromotionsFormStages}
    allowedRoles={getPageAllowedRoles("promotion")}
    title="Promotions"
  />
);

const PromotionExclusionDate = () => (
  <FormPage
    closeRedirect="/promotion-exclusion-dates"
    entityName="g4b_promotionexclusiondate"
    showInfo={true}
    stages={PromotionExclusionDateFormStages}
    allowedRoles={getPageAllowedRoles("promotion-exclusion-date")}
    title="Promotion Exclusion Date"
  />
);

const PromotionExclusionDates = () => (
  <FormPage
    stages={PromotionExclusionDatesFormStages}
    allowedRoles={getPageAllowedRoles("promotion-exclusion-date")}
    title="Promotion Exclusion Dates"
  />
);

const PromotionProductGroup = () => (
  <FormPage
    closeRedirect="/promotion-product-groups"
    entityName="g4b_productgroup"
    showInfo={true}
    stages={PromotionProductGroupFormStages}
    allowedRoles={getPageAllowedRoles("promotion-product-group")}
    title="Promotion Product Group"
  />
);

const PromotionProductGroups = () => (
  <FormPage
    stages={PromotionProductGroupsFormStages}
    allowedRoles={getPageAllowedRoles("promotion-product-group")}
    title="Promotion Product Groups"
  />
);

const PromotionType = () => (
  <FormPage
    closeRedirect="/promotion-types"
    entityName="g4b_promotiontype"
    showInfo={true}
    stages={PromotionTypeFormStages}
    allowedRoles={getPageAllowedRoles("promotion-type")}
    title="Promotion Type"
  />
);

const PromotionTypes = () => (
  <FormPage
    stages={PromotionTypesFormStages}
    allowedRoles={getPageAllowedRoles("promotion-type")}
    title="Promotion Types"
  />
);

const Question = () => (
  <FormPage
    closeRedirect="/questions"
    entityName="g4_question"
    showInfo={true}
    stages={QuestionFormStages}
    allowedRoles={getPageAllowedRoles("question")}
    title="Question"
  />
);

const Questions = () => (
  <FormPage
    stages={QuestionsFormStages}
    allowedRoles={getPageAllowedRoles("question")}
    title="Questions"
  />
);

const QuickStart = () => (
  <FormPage
    hideFormWhileSubmitting={true}
    methodName="quick-start/initial-load"
    renderSidebar={false}
    saveAndCloseRedirect="/global-settings"
    showSaveAndCloseButton={true}
    showSaveButton={false}
    stages={useQuickstartStages()}
    allowedRoles={getPageAllowedRoles("quick-start")}
    title="Quick Start"
  />
);

const Region = () => (
  <FormPage
    closeRedirect="/regions"
    entityName="g4_region"
    showInfo={true}
    stages={RegionFormStages}
    allowedRoles={getPageAllowedRoles("region")}
    title="Region"
  />
);

const Regions = () => (
  <FormPage
    stages={RegionsFormStages}
    allowedRoles={getPageAllowedRoles("region")}
    title="Regions"
  />
);

const SalesAndBookingsReport = () => (
  <Reporting
    reportType={ReportType.SalesAndBookings}
    allowedRoles={getPageAllowedRoles("sales-and-bookings-report")}
    title="Sales And Bookings Report"
  />
);

const SalesPlan = () => (
  <FormPage
    closeRedirect="/sales-plans"
    entityName="g4b_salesplan"
    showInfo={true}
    stages={SalesPlanFormStages}
    allowedRoles={getPageAllowedRoles("sales-plan")}
    title="Sales Plan"
  />
);

const SalesPlans = () => (
  <FormPage
    stages={SalesPlansFormStages}
    allowedRoles={getPageAllowedRoles("sales-plan")}
    title="Sales Plans"
  />
);

const SeatAllocation = () => (
  <FormPage
    entityName="g4b_seatallocation"
    stages={SeatAllocationFormStages}
    allowedRoles={getPageAllowedRoles("seat-allocation")}
    title="Seat Allocation"
  />
);

const SeatClass = () => (
  <FormPage
    closeRedirect="/seat-classes"
    entityName="g4b_seatclass"
    showInfo={true}
    stages={SeatClassFormStages}
    allowedRoles={getPageAllowedRoles("seat-class")}
    title="Seat Class"
  />
);

const SeatClasses = () => (
  <FormPage
    stages={SeatClassesFormStages}
    allowedRoles={getPageAllowedRoles("seat-class")}
    title="Seat Classes"
  />
);

const Serie = () => (
  <FormPage
    closeRedirect="/series"
    entityName="g4b_series"
    showInfo={true}
    stages={SerieFormStages}
    allowedRoles={getPageAllowedRoles("series")}
    title="Series"
  />
);

const Series = () => (
  <FormPage
    stages={SeriesFormStages}
    allowedRoles={getPageAllowedRoles("series")}
    title="Series"
  />
);

const Sitemap = () => (
  <FormPage
    entityName="sitemap"
    methodName="sitemap/update"
    showSaveAndCloseButton={false}
    stages={useSitemapStages()}
    allowedRoles={getPageAllowedRoles("sitemap")}
    title="Sitemap"
  />
);

const SubscriptionGroup = () => (
  <FormPage
    closeRedirect="/subscription-groups"
    entityName="g4c_subscriptiongroup"
    showInfo={true}
    stages={SubscriptionGroupFormStages}
    allowedRoles={getPageAllowedRoles("subscription-group")}
    title="Subscription Group"
  />
);

const SubscriptionGroups = () => (
  <FormPage
    stages={SubscriptionGroupsFormStages}
    allowedRoles={getPageAllowedRoles("subscription-group")}
    title="Subscription Groups"
  />
);

const TaxCode = () => (
  <FormPage
    closeRedirect="/tax-codes"
    entityName="g4b_taxcode"
    showInfo={true}
    stages={TaxCodeFormStages}
    allowedRoles={getPageAllowedRoles("tax-code")}
    title="Tax Code"
  />
);

const TaxCodes = () => (
  <FormPage
    stages={TaxCodesFormStages}
    allowedRoles={getPageAllowedRoles("tax-code")}
    title="Tax Codes"
  />
);

const TaxComponent = () => (
  <FormPage
    entityName="g4b_taxcomponent"
    showInfo={true}
    stages={TaxComponentFormStages}
    allowedRoles={getPageAllowedRoles("tax-component")}
    title="Tax Component"
  />
);

const TaxRate = () => (
  <FormPage
    entityName="g4b_taxrate"
    showInfo={true}
    stages={TaxRateFormStages}
    allowedRoles={getPageAllowedRoles("tax-rate")}
    title="Tax Rate"
  />
);

const TelegramQueue = () => (
  <FormPage
    entityName="g4b_telegramqueue"
    stages={TelegramQueueFormStages}
    allowedRoles={getPageAllowedRoles("telegram-queue")}
    title="Telegram Queue"
  />
);

const Terminal = () => (
  <FormPage
    closeRedirect="/terminals"
    saveAndNewRedirect="/terminal"
    entityName="g4b_terminal"
    showInfo={true}
    stages={TerminalFormStages}
    showSaveAndNewButton={true}
    allowedRoles={getPageAllowedRoles("terminal")}
    title="Terminal"
  />
);

const Terminals = () => (
  <FormPage
    stages={TerminalsFormStages}
    allowedRoles={getPageAllowedRoles("terminal")}
    title="Terminals"
  />
);

const TillGroup = () => (
  <FormPage
    closeRedirect="/till-groups"
    entityName="g4b_tillgroup"
    showInfo={true}
    stages={TillGroupFormStages}
    allowedRoles={getPageAllowedRoles("till-group")}
    title="Till Group"
  />
);

const TillGroups = () => (
  <FormPage
    stages={TillGroupsFormStages}
    allowedRoles={getPageAllowedRoles("till-group")}
    title="Till Groups"
  />
);

const VariantPriceList = () => (
  <FormPage
    closeRedirect="/variant-price-lists"
    entityName="g4b_variantpricelist"
    showInfo={true}
    stages={VariantPriceListFormStages}
    allowedRoles={getPageAllowedRoles("variant-price-list")}
    title="Variant Price List"
  />
);

const VariantPriceLists = () => (
  <FormPage
    stages={VariantPriceListsFormStages}
    allowedRoles={getPageAllowedRoles("variant-price-list")}
    title="Variant Price Lists"
  />
);

const VariantType = () => (
  <FormPage
    closeRedirect="/variant-types"
    entityName="g4b_varianttype"
    showInfo={true}
    stages={VariantTypeFormStages}
    allowedRoles={getPageAllowedRoles("variant-type")}
    title="Variant Type"
  />
);

const VariantTypes = () => (
  <FormPage
    stages={VariantTypesFormStages}
    allowedRoles={getPageAllowedRoles("variant-type")}
    title="Variant Types"
  />
);

const Venue = () => (
  <FormPage
    closeRedirect="/venue"
    entityName="g4b_venue"
    showInfo={true}
    stages={VenueFormStages}
    allowedRoles={getPageAllowedRoles("venue")}
    title="Venue"
  />
);

const Venues = () => (
  <FormPage
    stages={VenuesFormStages}
    allowedRoles={getPageAllowedRoles("venue")}
    title="Venues"
  />
);

const VenueConfiguration = () => (
  <FormPage
    closeRedirect="/venue-configuration"
    entityName="g4b_venueconfiguration"
    showInfo={true}
    stages={VenueConfigurationFormStages}
    showCopyButton={true}
    allowedRoles={getPageAllowedRoles("venue-configuration")}
    title="Venue Configuration"
  />
);

const VenueConfigurationBlock = () => (
  <FormPage
    entityName="g4b_venueconfigurationblock"
    showInfo={true}
    stages={VenueConfigurationBlockFormStages}
    allowedRoles={getPageAllowedRoles("venue-configuration-block")}
    title="Venue Configuration Block"
  />
);

const Voucher = () => (
  <FormPage
    closeRedirect="/vouchers"
    entityName="g4b_voucher"
    stages={VoucherFormStages}
    allowedRoles={getPageAllowedRoles("voucher")}
    title="Voucher"
  />
);

const Vouchers = () => (
  <FormPage
    stages={VouchersFormStages}
    allowedRoles={getPageAllowedRoles("voucher")}
    title="Vouchers"
  />
);

const Zone = () => (
  <FormPage
    closeRedirect="/zones"
    entityName="g4b_zone"
    showInfo={true}
    stages={ZoneFormStages}
    allowedRoles={getPageAllowedRoles("zone")}
    title="Zone"
  />
);

const Zones = () => (
  <FormPage
    stages={ZonesFormStages}
    allowedRoles={getPageAllowedRoles("zone")}
    title="Zones"
  />
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Home />} />
      <Route path="/abtest" element={<ABTest />} />
      <Route path="/abtests" element={<ABTests />} />
      <Route
        path="/access-control-provider"
        element={<AccessControlProvider />}
      />
      <Route
        path="/access-control-providers"
        element={<AccessControlProviders />}
      />
      <Route path="/access-point" element={<AccessPoint />} />
      <Route path="/access-points" element={<AccessPoints />} />
      <Route path="/account" element={<Account />} />
      <Route path="/accounts" element={<Accounts />} />
      <Route
        path="/additional-product"
        element={<AdditionalProduct />}
      />
      <Route
        path="/additional-products"
        element={<AdditionalProducts />}
      />
      <Route path="/admin-settings" element={<AdminSettings />} />
      <Route path="/answer-group" element={<AnswerGroup />} />
      <Route
        path="/answer-group-option"
        element={<AnswerGroupOption />}
      />
      <Route path="/answer-option" element={<AnswerOption />} />
      <Route
        path="/answer-option-category"
        element={<AnswerOptionCategory />}
      />
      <Route path="/blacklist" element={<Blacklist />} />
      <Route path="/block" element={<Block />} />
      <Route path="/blocks" element={<Blocks />} />
      <Route path="/block-seat" element={<BlockSeat />} />
      <Route
        path="/bookable-resource"
        element={<BookableResource />}
      />
      <Route
        path="/bookable-resources"
        element={<BookableResources />}
      />
      <Route path="/booking" element={<Booking />} />
      <Route path="/bookings" element={<Bookings />} />
      <Route path="/booking-ticket" element={<BookingTicket />} />
      <Route path="/calendar-item" element={<CalendarItem />} />
      <Route path="/campaign" element={<Campaign />} />
      <Route path="/campaigns" element={<Campaigns />} />
      <Route path="/channel" element={<Channel />} />
      <Route path="/channels" element={<Channels />} />
      <Route
        path="/channel-price-list"
        element={<ChannelPriceList />}
      />
      <Route
        path="/channel-price-lists"
        element={<ChannelPriceLists />}
      />
      <Route path="/channel-question" element={<ChannelQuestion />} />
      <Route path="/charity" element={<Charity />} />
      <Route path="/cities" element={<Cities />} />
      <Route path="/city" element={<City />} />
      <Route
        path="/commission-category"
        element={<CommissionCategory />}
      />
      <Route
        path="/commission-categories"
        element={<CommissionCategories />}
      />
      <Route path="/commission-group" element={<CommissionGroup />} />
      <Route
        path="/commission-groups"
        element={<CommissionGroups />}
      />
      <Route path="/commission-rate" element={<CommissionRate />} />
      <Route path="/commission-rates" element={<CommissionRates />} />
      <Route path="/communication" element={<Communication />} />
      <Route path="/communications" element={<Communications />} />
      <Route
        path="/communication-activity"
        element={<CommunicationActivity />}
      />
      <Route
        path="/communication-activities"
        element={<CommunicationActivities />}
      />
      <Route
        path="/communication-audience"
        element={<CommunicationAudience />}
      />
      <Route
        path="/communication-audiences"
        element={<CommunicationAudiences />}
      />
      <Route
        path="/communication-flow"
        element={<CommunicationFlow />}
      />
      <Route
        path="/communication-flows"
        element={<CommunicationFlows />}
      />
      <Route
        path="/communication-settings"
        element={<CommunicationSettings />}
      />
      <Route path="/contact" element={<Contact />} />
      <Route path="/contacts" element={<Contacts />} />
      <Route path="/contacts-report" element={<ContactsReport />} />
      <Route path="/countries" element={<Countries />} />
      <Route path="/country" element={<Country />} />
      <Route path="/coupon" element={<Coupon />} />
      <Route path="/coupons" element={<Coupons />} />
      <Route path="/coupon-product" element={<CouponProduct />} />
      <Route path="/coupon-type" element={<CouponType />} />
      <Route path="/coupon-types" element={<CouponTypes />} />
      <Route path="/cross-sell" element={<CrossSell />} />
      <Route path="/customer-address" element={<CustomerAddress />} />
      <Route
        path="/donation-batch-header"
        element={<DonationBatchHeader />}
      />
      <Route
        path="/donation-batch-headers"
        element={<DonationBatchHeaders />}
      />
      <Route
        path="/email-marketing-report"
        element={<EmailMarketingReport />}
      />
      <Route path="/financial-report" element={<FinancialReport />} />
      <Route path="/fixture" element={<Fixture />} />
      <Route path="/fixtures" element={<Fixtures />} />
      <Route path="/global-settings" element={<GlobalSettings />} />
      <Route path="/group" element={<Group />} />
      <Route path="/groups" element={<Groups />} />
      <Route path="/invoice" element={<Invoice />} />
      <Route
        path="/invoice-batch-header"
        element={<InvoiceBatchHeader />}
      />
      <Route
        path="/invoice-batch-headers"
        element={<InvoiceBatchHeaders />}
      />
      <Route
        path="/journal-daily-reports"
        element={<JournalDailyReportsPage />}
      />
      <Route path="/language" element={<Language />} />
      <Route path="/marketing-list" element={<MarketingList />} />
      <Route path="/marketing-lists" element={<MarketingLists />} />
      <Route path="/membership" element={<Membership />} />
      <Route
        path="/membership-access-token"
        element={<MembershipAccessToken />}
      />
      <Route
        path="/membership-account"
        element={<MembershipAccount />}
      />
      <Route
        path="/membership-accounts"
        element={<MembershipAccounts />}
      />
      <Route
        path="/membership-payment-plan"
        element={<MembershipPaymentPlan />}
      />
      <Route
        path="/memberships-report"
        element={<MembershipsReport />}
      />
      <Route path="/on-hold-audit" element={<OnHoldAudit />} />
      <Route path="/on-hold-reason" element={<OnHoldReason />} />
      <Route path="/on-hold-reasons" element={<OnHoldReasons />} />
      <Route
        path="/operational-report"
        element={<OperationalReport />}
      />
      <Route path="/operator" element={<Operator />} />
      <Route path="/operators" element={<Operators />} />
      <Route path="/operator-group" element={<OperatorGroup />} />
      <Route path="/operator-groups" element={<OperatorGroups />} />
      <Route path="/payment-batch" element={<PaymentBatch />} />
      <Route path="/payment-batches" element={<PaymentBatches />} />
      <Route
        path="/payment-batch-items"
        element={<PaymentBatchItems />}
      />
      <Route path="/payment-method" element={<PaymentMethod />} />
      <Route path="/payment-methods" element={<PaymentMethods />} />
      <Route path="/price-break" element={<PriceBreak />} />
      <Route
        path="/price-break-group"
        element={<PriceBreakGroup />}
      />
      <Route
        path="/price-break-groups"
        element={<PriceBreakGroups />}
      />
      <Route path="/printer" element={<Printer />} />
      <Route path="/printers" element={<Printers />} />
      <Route path="/print-transform" element={<PrintTransform />} />
      <Route path="/print-transforms" element={<PrintTransforms />} />
      <Route path="/product" element={<Product />} />
      <Route path="/products" element={<Products />} />
      <Route path="/product-calendar" element={<ProductCalendar />} />
      <Route
        path="/product-calendars"
        element={<ProductCalendars />}
      />
      <Route path="/product-category" element={<ProductCategory />} />
      <Route
        path="/product-categories"
        element={<ProductCategories />}
      />
      <Route path="/product-modifier" element={<ProductModifier />} />
      <Route path="/promotion" element={<Promotion />} />
      <Route path="/promotions" element={<Promotions />} />
      <Route
        path="/promotion-exclusion-date"
        element={<PromotionExclusionDate />}
      />
      <Route
        path="/promotion-exclusion-dates"
        element={<PromotionExclusionDates />}
      />
      <Route
        path="/promotion-product-group"
        element={<PromotionProductGroup />}
      />
      <Route
        path="/promotion-product-groups"
        element={<PromotionProductGroups />}
      />
      <Route path="/promotion-type" element={<PromotionType />} />
      <Route path="/promotion-types" element={<PromotionTypes />} />
      <Route path="/question" element={<Question />} />
      <Route path="/questions" element={<Questions />} />
      <Route path="/quick-start" element={<QuickStart />} />
      <Route path="/region" element={<Region />} />
      <Route path="/regions" element={<Regions />} />
      <Route
        path="/sales-and-bookings-report"
        element={<SalesAndBookingsReport />}
      />
      <Route path="/sales-plan" element={<SalesPlan />} />
      <Route path="/sales-plans" element={<SalesPlans />} />
      <Route path="/seat-allocation" element={<SeatAllocation />} />
      <Route path="/seat-class" element={<SeatClass />} />
      <Route path="/seat-classes" element={<SeatClasses />} />
      <Route path="/serie" element={<Serie />} />
      <Route path="/series" element={<Series />} />
      <Route path="/sitemap" element={<Sitemap />} />
      <Route
        path="/subscription-group"
        element={<SubscriptionGroup />}
      />
      <Route
        path="/subscription-groups"
        element={<SubscriptionGroups />}
      />
      <Route path="/tax-code" element={<TaxCode />} />
      <Route path="/tax-codes" element={<TaxCodes />} />
      <Route path="/tax-component" element={<TaxComponent />} />
      <Route path="/tax-rate" element={<TaxRate />} />
      <Route path="/telegram-queue" element={<TelegramQueue />} />
      <Route path="/terminal" element={<Terminal />} />
      <Route path="/terminals" element={<Terminals />} />
      <Route path="/till-group" element={<TillGroup />} />
      <Route path="/till-groups" element={<TillGroups />} />
      <Route
        path="/variant-price-list"
        element={<VariantPriceList />}
      />
      <Route
        path="/variant-price-lists"
        element={<VariantPriceLists />}
      />
      <Route path="/variant-type" element={<VariantType />} />
      <Route path="/variant-types" element={<VariantTypes />} />
      <Route path="/venue" element={<Venue />} />
      <Route path="/venues" element={<Venues />} />
      <Route
        path="/venue-configuration"
        element={<VenueConfiguration />}
      />
      <Route
        path="/venue-configuration-block"
        element={<VenueConfigurationBlock />}
      />
      <Route path="/voucher" element={<Voucher />} />
      <Route path="/vouchers" element={<Vouchers />} />
      <Route path="/zone" element={<Zone />} />
      <Route path="/zones" element={<Zones />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

function App() {
  return (
    <GlobalProvider>
      <RouterProvider router={router} />
    </GlobalProvider>
  );
}

export default App;
