import lookups from "./lookups";
import { TargetedAtType } from "../js/enums";
import { SubscriptionGroupFormStages } from "./subscriptionGroup";
import AddRemoveRecordsTable from "../components/elements/AddRemoveRecordsTable";
import {
  contactSearchFilterArray,
  existingContactRecordsSearchFilterArray,
} from "../components/form/contact/Helpers";

export const MarketingListFormStages = [
  {
    title: "Marketing List",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "listname",
            className: "col-md-3",
            description:
              "Type a name for the marketing list so that it is identified correctly in lists.",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "createdfromcode",
            className: "col-md-3",
            description:
              "Select the type of members that this marketing list will contain.",
            default: TargetedAtType.Contact,
            label: "Targeted At",
            type: "picklist",
            enum: TargetedAtType,
          },
          {
            name: "g4b_code",
            className: "col-md-3",
            description: "",
            label: "Code",
            type: "nvarchar",
          },
          {
            name: "g4c_sequencenumber",
            className: "col-md-3",
            description: "",
            label: "Sequence",
            type: "int",
          },
        ],
      },
      {
        name: "Subscriptions",
        fields: [
          {
            name: "g4c_issubscription",
            className: "col-md-3",
            default: false,
            description: "",
            label: "Is Subscription",
            type: "bit",
          },
          {
            allowAdd: true,
            allowEdit: true,
            name: "g4c_subscriptiongroupid",
            description: "",
            label: "Subscription Group",
            lookup: lookups.g4c_subscriptiongroup.all,
            type: "lookup",
            subFormStages: SubscriptionGroupFormStages,
            className: "col-md-4",
          },
          {
            name: "g4c_subscriptiondescription",
            className: "col-md-5",
            description: "",
            label: "Subscription Description",
            type: "nvarchar",
          },
          {
            name: "g4b_includeinmaintenanceprocess",
            className: "col-md-3",
            description: "Include this marketing list in the Marketing list maintenance schedule",
            label: "Include in the Maintenance Process",
            type: "bit",
          },
        ],
      },
      {
        name: "Members",
        fields: [
          {
            name: "listmembertable",
            className: "col-12",
            component: AddRemoveRecordsTable,
            allowEdit: true,
            existingRecordsEntitySearchName: "listmember",
            existingRecordsEntitySearchFilter:
              "ignoreresultexceedcheck",
            existingRecordsSearchFilterArray:
              existingContactRecordsSearchFilterArray,
            lookup: lookups.contact.all,
            type: "component",
            recordsAddValue: "membersToAdd",
            recordsRemoveValue: "membersToRemove",
            recordsTableColumns: [
              {
                headingName: "Name",
                className: "col-primary",
                isNameField: true,
                type: "string",
                linkById: true,
                linkedEntityPath: "/contact",
                openInNewTab: true,
              },
              {
                headingName: "Email Address",
                className: "col-primary",
                fieldName: "emailaddress1",
                type: "string",
              },
              {
                headingName: "Date Added",
                className: "col-date",
                fieldName: "createdon",
                type: "datetime",
              },
            ],
            addRecordsEntitySearchName: "contact",
            addRecordsEntitySearchFilter: "filteroutlistmember",
            addRecordsSearchFilterArray: contactSearchFilterArray,
            addRecordsTableColumns: [
              {
                headingName: "Name",
                className: "col-primary",
                isNameField: true,
                type: "string",
                linkById: true,
                linkedEntityPath: "/contact",
                openInNewTab: true,
              },
              {
                headingName: "Email Address",
                className: "col-primary",
                fieldName: "emailaddress1",
                type: "string",
              },
            ],
          },
          { name: "membersToAdd", hidden: true },
          { name: "membersToRemove", hidden: true },
        ],
      },
    ],
  },
];
