import lookups from "./lookups";
import { checkDateTimeIsAfterToday } from "../js/utility";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { ABTestFormStages } from "./abtest";
import { ABTestPublishedStatus } from "../js/enums";

export const ABTestsFormStages = [
  {
    title: "A/B Tests",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "statuscode",
              className: "col-short-name",
              type: "statusbadge",
              enum: ABTestPublishedStatus,
            },
            {
              name: "g4c_communicationaid",
              className: "col-short-name",
            },
            {
              name: "g4c_communicationbid",
              className: "col-short-name",
            },
            {
              name: "g4c_metric",
              className: "col-filter",
            },
            {
              name: "g4c_winnertype",
              className: "col-filter",
            },
            {
              name: "g4c_testpercentage",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4c_startdate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4c_enddate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4c_testperiodenddate",
              className: "col-date",
              label: "Test Period End Date",
              type: "datetime",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name"],
              type: "text",
            },
            {
              name: "g4c_communicationaid",
              type: "select",
            },
            {
              name: "g4c_communicationbid",
              type: "select",
            },
            {
              name: "g4c_metric",
              type: "select",
            },
            {
              name: "Hide Expired A/B Tests",
              type: "bit",
              defaultValue: true,
              filterAction: (filterValue, values) => {
                if (
                  filterValue &&
                  values &&
                  values.Fields &&
                  values.Fields.g4c_enddate
                ) {
                  return checkDateTimeIsAfterToday(
                    values.Fields.g4c_enddate
                  );
                }
                return true;
              },
            },
            {
              name: "g4c_startdate",
              type: "datetime",
            },
            {
              name: "g4c_enddate",
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "g4c_startdate",
          initialOrderedByAsc: false,
          linkForNewRecord: "/abtest",
          lookup: lookups.g4c_abtest.all,
          subFormStages: ABTestFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
