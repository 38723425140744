const getCommClickRatePercentageText = (
  HardBounces,
  SoftBounces,
  Statistics,
  UniqueClicks
) => {
  const { Sent } = Statistics;
  const hardBounces = parseInt(HardBounces, 10);
  const softBounces = parseInt(SoftBounces, 10);
  const totalBounces = hardBounces + softBounces;
  const sent = parseInt(Sent, 10);

  const totalSent = sent - totalBounces;

  const uniqueClicks = parseInt(UniqueClicks, 10);
  const uniqueClicksPercentage = (
    (uniqueClicks * 100) /
    totalSent
  ).toFixed(2);

  return totalSent === 0 ? "N/A" : `${uniqueClicksPercentage}%`;
};

const getCommOpenRatePercentageText = (
  HardBounces,
  SoftBounces,
  Statistics
) => {
  const { Engagements, Sent } = Statistics;
  const { TotalOpensUnique } = Engagements;
  const hardBounces = parseInt(HardBounces, 10);
  const softBounces = parseInt(SoftBounces, 10);
  const totalBounces = hardBounces + softBounces;
  const sent = parseInt(Sent, 10);
  const totalOpensUnique = parseInt(TotalOpensUnique, 10);

  const totalSent = sent - totalBounces;
  const openRate =
    sent > 0 ? (totalOpensUnique * 100) / totalSent : 0;

  return `${openRate.toFixed(2)}%`;
};

const getCommRevenueValueText = (DefaultAttribution) => {
  const { FormatedTotalRevenue } = DefaultAttribution;
  return FormatedTotalRevenue;
};

export {
  getCommClickRatePercentageText,
  getCommOpenRatePercentageText,
  getCommRevenueValueText,
};
