import { CommissionCategoryFormStages } from "./commissionCategory";
import { CommissionGroupFormStages } from "./commissionGroup";
import lookups from "./lookups";

export const CommissionRateFormStages = [
  {
    title: "Commission Rate",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_commissiongroupid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Commission Group",
            lookup: lookups.g4b_commissiongroup.all,
            subFormStages: CommissionGroupFormStages,
            type: "lookup",
          },
          {
            name: "g4b_commissioncategoryid",
            allowAdd: true,
            allowEdit: true,
            description: "",
            label: "Commission Category",
            lookup: lookups.g4b_commissioncategory.all,
            subFormStages: CommissionCategoryFormStages,
            type: "lookup",
          },
          {
            name: "g4b_rate",
            description: "",
            label: "Rate",
            type: "float",
          },
          {
            name: "g4b_giftaidrate",
            description: "",
            label: "Gift Aid Rate",
            type: "float",
          },
          { name: "g4b_productid", hidden: true },
          { name: "g4b_giftaidproductid", hidden: true },
        ],
      },
    ],
  },
];
