import { CouponFormStages } from "./coupon";
import lookups from "./lookups";
import { CouponVoucherStatus } from "../js/enums";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const CouponsFormStages = [
  {
    title: "Coupons",
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          useSubmitButton: true,
          fields: [
            {
              name: "g4b_couponcode",
              className: "col-short-name",
            },
            {
              name: "g4b_couponstatus",
              className: "col-short-name",
              type: "statusbadge",
              enum: CouponVoucherStatus,
            },
            {
              name: "g4b_customername",
              className: "col-name",
            },
            {
              name: "g4b_purchasedate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_notvaliduntildate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_redeembydate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_usebydate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_expirydate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4b_coupontypeidname",
              label: "Coupon Type",
              className: "col-name",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "g4b_couponcode",
              type: "text",
            },
            {
              name: "g4b_couponstatus",
              defaultValue: [CouponVoucherStatus.Available],
              enum: CouponVoucherStatus,
              label: "Coupon Status",
              type: "select",
            },
            {
              name: "g4b_customername",
              type: "text",
            },
            {
              name: "g4b_coupontypeid",
              lookup: lookups.g4b_coupontype.all,
              type: "select",
            },
            {
              name: "g4b_purchasedate",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "g4b_notvaliduntildate",
              type: "datetime",
            },
            {
              name: "g4b_redeembydate",
              type: "datetime",
            },
            {
              name: "g4b_usebydate",
              type: "datetime",
            },
            {
              name: "g4b_expirydate",
              type: "datetime",
            },
          ],
          linkForNewRecord: "/coupon",
          lookup: lookups.g4b_coupon.all,
          subFormStages: CouponFormStages,
          useAlphabetPagination: false,
          useSubForm: false,
        },
      },
    ],
  },
];
