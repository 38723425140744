import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { BookingStatus } from "../js/enums";
import { BookingFormStages } from "./booking";
import lookups from "./lookups";

export const InvoiceFormStages = [
  {
    title: "",
    id: "summary",
    displayEditButtonFromState: (_) => false,
    editOnOverview: false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "invoicenumber",
            description: "",
            label: "Reference",
            type: "nvarchar",
          },
          {
            name: "totalamount",
            label: "Total",
            type: "money",
          },
          {
            name: "commission",
            label: "Commission",
            type: "money",
          },
        ],
      },
    ],
  },
  {
    title: "Bookings",
    id: "bookings",
    displayEditButtonFromState: (_) => false,
    editOnOverview: false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_bookingreference",
              label: "Booking Reference",
              className: "col-primary",
              isNameField: true,
            },
            {
              name: "g4b_bookingstatus",
              label: "Booking Status",
              className: "col-name",
              type: "statusbadge",
              enum: BookingStatus,
            },
            {
              name: "g4b_channelidname",
              label: "Channel",
              className: "col-short-name",
            },
            {
              name: "g4b_totalprice",
              label: "Total Price",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_discountvalue",
              label: "Discount Value",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_commission",
              label: "Commission",
              className: "col-number",
              type: "money",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              textFieldsSearch: ["g4b_bookingreference"],
              type: "text",
            },
            {
              name: "g4b_bookingstatus",
              enum: BookingStatus,
              label: "Booking Status",
              type: "select",
            },
            {
              name: "g4b_channelidname",
              label: "Channel",
              type: "select",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "createdon",
          initialOrderedByAsc: false,
          linkForNewRecord: "/booking",
          loadStateRelatedEntityName: "g4b_booking",
          lookup: lookups.g4b_booking.all,
          openNewTab: true,
          subFormStages: BookingFormStages,
          useSubForm: false,
        },
      },
    ],
  },
];
