import { NavLink } from "react-router-dom";
import classNames from "classnames";

function NavigationLink({ text, to }) {
  return (
    <li>
      <NavLink
        to={to}
        className={({ isActive }) =>
          classNames(isActive ? "active" : "", "nav-link")
        }
        state={{
          canGoBack: true,
        }}
      >
        {text}
      </NavLink>
    </li>
  );
}

export default NavigationLink;
