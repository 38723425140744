import { Link } from "react-router-dom";
import { getLinkForNewRecord } from "./Helpers";

function AddRecordButton({ filters, parentId, table }) {
  return (
    <Link
      to={getLinkForNewRecord(table, parentId, filters)}
      className="btn btn-primary"
      state={{
        canGoBack: true,
      }}
    >
      New
    </Link>
  );
}

export default AddRecordButton;
