import { InvoiceFormStages } from "./invoice";
import lookups from "./lookups";

export const InvoiceBatchHeaderFormStages = [
  {
    title: "",
    id: "summary",
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_fromdate",
            description: "",
            label: "From Date",
            type: "datetime",
          },
          {
            name: "g4b_todate",
            description: "",
            label: "To Date",
            type: "datetime",
          },
          {
            name: "g4b_invoicecount",
            description: "",
            disabled: true,
            label: "Count",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Invoices",
    id: "invoices",
    displayEditButtonFromState: (_) => false,
    displayIfFromState: (state) => state.g4b_invoicebatchheaderid,
    sections: [
      {
        name: "",
        table: {
          allowEdit: true,
          fields: [
            {
              name: "invoicenumber",
              className: "col-name",
            },
            {
              name: "totalamount",
              className: "col-number",
              type: "money",
            },
          ],
          filterByStatus: true,
          linkedEntityId: "invoiceid",
          loadStateRelatedEntityName: "invoice",
          lookup: lookups.invoice.all,
          subFormStages: InvoiceFormStages,
          useSubForm: false,
          linkForNewRecord: "/invoice",
        },
      },
    ],
  },
];
