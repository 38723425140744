import React from "react";
import Engagements from "./Engagements";
import Interactions from "./Interactions";
import Bookings from "./Bookings";
import HeatMap from "./HeatMap";

function CommunicationStatisticsValues({ formData }) {
  const {
    Clicks,
    DefaultAttribution,
    EmailHtml,
    HardBounces,
    PublishedItems,
    SoftBounces,
    Statistics,
    TicketingInstalled,
    UniqueClicks,
  } = formData;

  return (
    <>
      <div className="col-12">
        <Engagements
          HardBounces={HardBounces}
          PublishedItems={PublishedItems}
          SoftBounces={SoftBounces}
          Statistics={Statistics}
        />
      </div>
      <div className="col-12">
        <Interactions
          Clicks={Clicks}
          HardBounces={HardBounces}
          SoftBounces={SoftBounces}
          Statistics={Statistics}
          UniqueClicks={UniqueClicks}
        />
      </div>
      <div className="col-12">
        <HeatMap
          EmailHtml={EmailHtml}
          Statistics={Statistics}
          UniqueClicks={UniqueClicks}
        />
      </div>
      {TicketingInstalled && DefaultAttribution && (
        <div className="col-12">
          <Bookings DefaultAttribution={DefaultAttribution} />
        </div>
      )}
    </>
  );
}

export default CommunicationStatisticsValues;
