import { useCallback, useEffect, useState } from "react";
import { FormDatePicker, FormLabel } from "./_Elements";
import { getFieldLabelForFormTable } from "../form/Helpers";
import { isLaterTime, isSameOrBeforeDate } from "../../js/utility";

function SingleDateTimeFilter({
  displayLabel,
  filter,
  filters,
  setFilters,
  subFormStages,
}) {
  const filterNameDate = filter.name + "-Date";
  const filterNameFrom = filter.name + "-From";
  const filterNameTo = filter.name + "-To";
  const [currentFilterDateValue, setCurrentFilterDateValue] =
    useState(null);
  const [currentFilterFromValue, setCurrentFilterFromValue] =
    useState(null);
  const [currentFilterToValue, setCurrentFilterToValue] =
    useState(null);
  const [initialFilterSet, setInitialFilterSet] = useState(true);

  const updateFilterValue = useCallback(
    (date, timeFrom, timeTo, filter) => {
      let filterNameDate = filter.name + "-Date";
      let filterNameFrom = filter.name + "-From";
      let filterNameTo = filter.name + "-To";

      let updateFilterDateRecord = {
        Key: filterNameDate,
        FilterAction: filter.filterAction,
        Type: "datetime",
        Value: date,
      };

      setCurrentFilterDateValue(date);

      let updateFilterFromRecord = {
        Key: filterNameFrom,
        FilterAction: filter.filterAction,
        Type: "datetime",
        Value: timeFrom,
      };
      setCurrentFilterFromValue(timeFrom);

      let updateFilterToRecord = {
        Key: filterNameTo,
        FilterAction: filter.filterAction,
        Type: "datetime",
        Value: timeTo,
      };
      setCurrentFilterToValue(timeTo);

      setFilters([
        ...filters.filter(
          (f) =>
            f.Key !== filterNameDate &&
            f.Key !== filterNameFrom &&
            f.Key !== filterNameTo
        ),
        updateFilterDateRecord,
        updateFilterFromRecord,
        updateFilterToRecord,
      ]);
    },
    [filters, setFilters]
  );

  //This useEffect should only be ran once upon loading up the datetime filter modal
  useEffect(() => {
    if (initialFilterSet) {
      let currentFilterDate = filters.some(
        (f) => f.Key === filterNameDate
      )
        ? filters.find((f) => f.Key === filterNameDate)
        : "";
      let currentFilterDateValue =
        currentFilterDate && currentFilterDate.Value
          ? new Date(currentFilterDate.Value)
          : "";

      let currentFilterFrom = filters.some(
        (f) => f.Key === filterNameFrom
      )
        ? filters.find((f) => f.Key === filterNameFrom)
        : "";
      let currentFilterFromValue =
        currentFilterFrom && currentFilterFrom.Value
          ? new Date(currentFilterFrom.Value)
          : "";

      let currentFilterTo = filters.some(
        (f) => f.Key === filterNameTo
      )
        ? filters.find((f) => f.Key === filterNameTo)
        : "";
      let currentFilterToValue =
        currentFilterTo && currentFilterTo.Value
          ? new Date(currentFilterTo.Value)
          : "";

      updateFilterValue(
        currentFilterDateValue,
        currentFilterFromValue,
        currentFilterToValue,
        filter
      );
      setInitialFilterSet(false);
    }
  }, [
    filter,
    filterNameDate,
    filterNameFrom,
    filterNameTo,
    filters,
    initialFilterSet,
    updateFilterValue,
  ]);

  return (
    <div>
      {displayLabel && (
        <label className="form-label me-auto">
          {displayLabel
            ? getFieldLabelForFormTable(filter, subFormStages)
            : ""}
        </label>
      )}
      <FormDatePicker
        handleDateChange={(date) =>
          updateFilterValue(
            date,
            currentFilterFromValue,
            currentFilterToValue,
            filter
          )
        }
        filterDate={(date) =>
          currentFilterFromValue
            ? isSameOrBeforeDate(date, new Date())
            : true
        }
        labelInline={true}
        locale={"en-GB"}
        name={filterNameFrom}
        renderCustomHeader={filter.renderCustomHeader}
        selected={currentFilterDateValue}
      />
      <div className="d-flex flex-row justify-content-between mt-3">
        <>
          <div>
            <FormLabel
              className="form-label me-2"
              label="From"
              name={filterNameFrom}
            />
            <div className="d-inline-block">
              <FormDatePicker
                handleDateChange={(date) =>
                  updateFilterValue(
                    currentFilterDateValue,
                    date,
                    currentFilterToValue,
                    filter
                  )
                }
                labelInline={true}
                locale={"en-GB"}
                name={filterNameFrom}
                dateFormat={"HH:mm"}
                showTimeSelect={true}
                showTimeSelectOnly={true}
                selected={currentFilterFromValue}
              />
            </div>
          </div>
          <div>
            <FormLabel
              className="form-label me-2"
              label="To"
              name={filterNameTo}
            />
            <div className="d-inline-block">
              <FormDatePicker
                filterTime={(time) =>
                  currentFilterFromValue
                    ? isLaterTime(time, currentFilterFromValue)
                    : true
                }
                handleDateChange={(date) =>
                  updateFilterValue(
                    currentFilterDateValue,
                    currentFilterFromValue,
                    date,
                    filter
                  )
                }
                locale={"en-GB"}
                name={filterNameTo}
                dateFormat={"HH:mm"}
                showTimeSelect={true}
                showTimeSelectOnly={true}
                selected={currentFilterToValue}
              />
            </div>
          </div>
        </>
      </div>
    </div>
  );
}

export default SingleDateTimeFilter;
