import lookups from "./lookups";
import { CouponVoucherStatus } from "../js/enums";
import { BookingFormStages } from "./booking";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const CouponFormStages = [
  {
    title: "Coupon",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_couponcode",
            className: "col-md-6",
            description: "",
            label: "Coupon Code",
            type: "nvarchar",
          },
          {
            name: "g4b_couponstatus",
            className: "col-md-6",
            description: "",
            label: "Coupon Status",
            type: "picklist",
            enum: CouponVoucherStatus,
          },
          {
            name: "g4b_coupontypeid",
            className: "col-md-6",
            description: "",
            label: "Coupon Type",
            lookup: lookups.g4b_coupontype.all,
            type: "lookup",
          },
          {
            name: "g4b_purchasedate",
            className: "col-md-6",
            description: "",
            label: "Purchase Date",
            type: "datetime",
          },
          {
            name: "g4b_notvaliduntildate",
            className: "col-md-6",
            description: "",
            label: "Not Valid Until Date",
            type: "datetime",
          },
          {
            name: "g4b_redeembydate",
            className: "col-md-6",
            description: "",
            label: "Redeem By Date",
            type: "datetime",
          },
          {
            name: "g4b_usebydate",
            className: "col-md-6",
            description: "",
            label: "Use By Date",
            type: "datetime",
          },
          {
            name: "g4b_expirydate",
            className: "col-md-6",
            dateFormat: "dd/MM/yyyy",
            description: "",
            label: "Expiry Date",
            type: "datetime",
          },
        ],
      },
    ],
  },
  {
    title: "Purchaser",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_contactid",
            hidden: true,
            type: "nvarchar",
          },
          {
            name: "g4b_customername",
            className: "col-md-4",
            description: "",
            label: "Customer Name",
            linkedEntityId: "g4b_contactid",
            linkForNewRecord: "/contact",
            type: "nvarchar",
          },
          {
            name: "emailaddress1",
            className: "col-md-4",
            description: "",
            label: "Email",
            type: "nvarchar",
          },
          {
            name: "telephone1",
            className: "col-md-4",
            description: "",
            label: "Business Phone",
            type: "nvarchar",
          },
          {
            name: "telephone2",
            className: "col-md-4",
            description: "",
            label: "Home Phone",
            type: "nvarchar",
          },
          {
            name: "mobilephone",
            className: "col-md-4",
            description: "",
            label: "Mobile Phone",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Purchase Booking Products",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_bookingreference",
              label: "Booking Reference",
              className: "col-short-name",
            },
            {
              name: "g4b_protoproductidname",
              label: "Product Variant",
              className: "col-short-name",
            },
            {
              name: "g4b_number",
              label: "Quantity",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_bookingprice",
              label: "Booking Price",
              className: "col-number",
              type: "money",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/booking",
          loadStateRelatedEntityName:
            "g4b_coupon_bookingproductpurchase",
          lookup: lookups.g4b_booking.all,
          subFormStages: BookingFormStages,
          useSubForm: false,
        },
      },
    ],
  },
  {
    title: "Booking Products Redeemed",
    editOnOverview: false,
    displayEditButtonFromState: (_) => false,
    displayIfFromState: (state) =>
      String(state.g4b_couponstatus) ===
      String(CouponVoucherStatus.Redeemed),
    sections: [
      {
        name: "",
        table: {
          allowAdd: false,
          allowEdit: true,
          hideNameColumn: true,
          fields: [
            {
              name: "g4b_bookingreference",
              label: "Booking Reference",
              className: "col-short-name",
            },
            {
              name: "g4b_protoproductidname",
              label: "Product Variant",
              className: "col-short-name",
            },
            {
              name: "g4b_number",
              label: "Quantity",
              className: "col-number",
              type: "string",
            },
            {
              name: "g4b_bookingprice",
              label: "Booking Price",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4m_membershipinitialfee",
              label: "Membership Initial Fee",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4m_membershipjoiningfee",
              label: "Membership Joining Fee",
              className: "col-number",
              type: "money",
            },
            {
              name: "g4b_discountvalue",
              label: "Discount Value",
              className: "col-number",
              type: "money",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: false,
          filters: [
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/booking",
          loadStateRelatedEntityName:
            "g4b_coupon_bookingproductredeem",
          lookup: lookups.g4b_booking.all,
          subFormStages: BookingFormStages,
          useSubForm: false,
        },
      },
    ],
  },
];
