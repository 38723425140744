import { CommunicationFormStages } from "./communication";
import lookups from "./lookups";
import { checkDateTimeIsAfterToday } from "../js/utility";
import {
  CommunicationAudienceType,
  CommunicationPublishedStatus,
} from "../js/enums";
import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";

export const CommunicationsFormStages = [
  {
    title: "Communications",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "statuscode",
              className: "col-short-name",
              type: "statusbadge",
              enum: CommunicationPublishedStatus,
            },
            {
              name: "g4c_communicationtype",
              className: "col-short-name",
            },
            {
              name: "g4c_campaignid",
              className: "col-short-name",
            },
            {
              name: "g4c_audienceid",
              className: "col-short-name",
            },
            {
              name: "g4c_startdate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4c_enddate",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "g4c_sid",
              className: "col-short-name",
            },
            {
              name: "g4c_usedynamiccontent",
              className: "col-bool",
            },
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name"],
              type: "text",
            },
            {
              name: "statuscode",
              enum: CommunicationPublishedStatus,
              type: "select",
            },
            {
              name: "g4c_campaignid",
              type: "select",
            },
            {
              name: "Include Transactional",
              type: "bit",
              defaultValue: true,
              filterAction: (filterValue, values) => {
                //Only return the records that have a g4c_communicationtype of Marketing if unchecked
                //Otherwise return all records
                if (
                  !filterValue &&
                  values &&
                  values.Fields &&
                  values.Fields.g4c_communicationtype &&
                  values.Fields.g4c_communicationtype.Id
                ) {
                  return (
                    values.Fields.g4c_communicationtype.Id ===
                    CommunicationAudienceType.Marketing
                  );
                }
                return true;
              },
            },
            {
              name: "Hide Expired Comms",
              type: "bit",
              defaultValue: true,
              filterAction: (filterValue, values) => {
                if (
                  filterValue &&
                  values &&
                  values.Fields &&
                  values.Fields.g4c_enddate
                ) {
                  return checkDateTimeIsAfterToday(
                    values.Fields.g4c_enddate
                  );
                }
                return true;
              },
            },
            {
              name: "g4c_startdate",
              type: "datetime",
            },
            {
              name: "g4c_enddate",
              type: "datetime",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          initialOrderedBy: "g4c_startdate",
          initialOrderedByAsc: false,
          linkForNewRecord: "/communication",
          lookup: lookups.g4c_communication.all,
          subFormStages: CommunicationFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
