import lookups from "./lookups";
import { AccessPointFormStages } from "./accessPoint";
import {
  PaymentDeviceEngagement,
  SkipBeneficiaryLevel,
} from "../js/enums";
import { TillGroupFormStages } from "./tillGroup";

export const TerminalFormStages = [
  {
    title: "General",
    id: "general",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_name",
            description: "",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_ip",
            description: "The ID of the terminal",
            label: "ID",
            required: true,
            type: "nvarchar",
          },
          {
            allowAdd: true,
            allowEdit: true,
            name: "g4b_tillgroupid",
            description: "Till Group associated with this terminal.",
            label: "Till Group",
            lookup: lookups.g4b_tillgroup.all,
            subFormStages: TillGroupFormStages,
            type: "lookup",
          },
        ],
      },
    ],
  },
  {
    title: "Printer Defaults",
    id: "printer-defaults",
    sections: [
      {
        name: "Member Cards",
        fields: [
          {
            name: "g4b_membercardprinterid",
            className: "col-md-6",
            description:
              "Printer associated with the terminal for handling member cards.",
            label: "Member Card Printer",
            lookup: lookups.g4b_printer.all,
            type: "lookup",
          },
          {
            name: "g4b_membercardtransformid",
            className: "col-md-6",
            description:
              "Print Transform associated with the terminal for printing member cards.",
            label: "Member Card Transform",
            lookup: lookups.g4b_printtransform.all,
            type: "lookup",
          },
        ],
      },
      {
        name: "Receipts",
        fields: [
          {
            name: "g4b_receiptprinterid",
            className: "col-md-6",
            description:
              "Printer associated with terminal for printing receipts.",
            label: "Receipt Printer",
            lookup: lookups.g4b_printer.all,
            type: "lookup",
          },
          {
            name: "g4b_receipttransformid",
            className: "col-md-6",
            description:
              "Print Transform associated with terminal for printing receipts.",
            label: "Receipt Transform",
            lookup: lookups.g4b_printtransform.all,
            type: "lookup",
          },
        ],
      },
      {
        name: "Session Reports",
        fields: [
          {
            name: "g4b_sessionreportprinterid",
            className: "col-md-6",
            description:
              "Printer associated with terminal for printing session reports.",
            label: "Session Report Printer",
            lookup: lookups.g4b_printer.all,
            type: "lookup",
          },
          {
            name: "g4b_sessionreporttransformid",
            className: "col-md-6",
            description:
              "Print Transform associated with terminal for printing session reports.",
            label: "Session Report Transform",
            lookup: lookups.g4b_printtransform.all,
            type: "lookup",
          },
        ],
      },
      {
        name: "Tickets",
        fields: [
          {
            name: "g4b_ticketprinterid",
            className: "col-md-6",
            description:
              "Printer associated with terminal for printing tickets.",
            label: "Ticket Printer",
            lookup: lookups.g4b_printer.all,
            type: "lookup",
          },
          {
            name: "g4b_tickettransformid",
            className: "col-md-6",
            description:
              "Print Transform associated with terminal for printing tickets..",
            label: "Ticket Transform",
            lookup: lookups.g4b_printtransform.all,
            type: "lookup",
          },
        ],
      },
      {
        name: "Till Reports",
        fields: [
          {
            name: "g4b_tillreportprinterid",
            className: "col-md-6",
            description:
              "Printer associated with terminal for printing till reports.",
            label: "Till Report Printer",
            lookup: lookups.g4b_printer.all,
            type: "lookup",
          },
          {
            name: "g4b_tillreporttransformid",
            className: "col-md-6",
            description:
              "Print Transform associated with terminal for printing till reports.",
            label: "Till Report Transform",
            lookup: lookups.g4b_printtransform.all,
            type: "lookup",
          },
        ],
      },
      {
        name: "Vouchers",
        fields: [
          {
            name: "g4b_voucherprinterid",
            className: "col-md-6",
            description:
              "Printer associated with terminal for printing vouchers.",
            label: "Voucher Printer",
            lookup: lookups.g4b_printer.all,
            type: "lookup",
          },
          {
            name: "g4b_vouchertransformid",
            className: "col-md-6",
            description:
              "Print Transform associated with terminal for printing vouchers.",
            label: "Voucher Transform",
            lookup: lookups.g4b_printtransform.all,
            type: "lookup",
          },
        ],
      },
    ],
  },
  {
    title: "Additional Printers",
    id: "additional-printers",
    editOnOverview: true,
    sections: [
      {
        name: "",
        fields: [
          {
            className: "col-12 mb-3",
            name: "g4b_printer",
            description: "",
            label: "Printers",
            inline: true,
            lookup: lookups.g4b_printer.all,
            type: "checklist",
          },
        ],
      },
    ],
  },
  {
    title: "Access Control",
    id: "access-control",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_displayaccessgatetab",
            className: "col-md-4",
            default: false,
            description: "Display the access gate tab in EPos",
            label: "Display Access Gate Tab",
            type: "bit",
          },
          {
            allowAdd: true,
            allowEdit: true,
            name: "g4b_accesspoint",
            className: "col-md-4",
            description: "Access Point associated with this terminal",
            label: "Access Point",
            lookup: lookups.g4b_accesspoint.all,
            subFormStages: AccessPointFormStages,
            type: "lookup",
          },
          {
            name: "g4b_gateservicelocation",
            className: "col-md-4",
            description:
              "This is the location for the access control gate services file on the deployed services",
            label: "Gate Service Location",
            type: "nvarchar",
          },
        ],
      },
    ],
  },
  {
    title: "Peripherals",
    id: "peripherals",
    sections: [
      {
        name: "Card Reader",
        fields: [
          {
            name: "g4b_cardreaderattached",
            default: false,
            description:
              "Is there a card reader attached to this terminal",
            label: "Card Reader Attached",
            type: "bit",
          },
          {
            name: "g4b_cardreadername",
            description: "Card Reader Name",
            label: "Card Reader Name",
            type: "nvarchar",
          },
        ],
      },
      {
        name: "Payment Device",
        fields: [
          {
            name: "g4b_paymentdevicelogonid",
            description:
              "The logon id for a PDQ device. This can be the normal user id that allows payments to be taken or the admin id to allow refunds",
            label: "Device Logon Id",
            type: "nvarchar",
          },
          {
            name: "g4b_paymentdevicelogonpassword",
            description:
              "The logon password for a PDQ device. This can be the normal user password that allows payments to be taken or the admin password.",
            label: "Device Logon Password",
            type: "nvarchar",
          },
          {
            name: "g4b_paymentdeviceengagement",
            default: true,
            description:
              "How interaction with the payment device is to take place",
            label: "Device Engagement",
            type: "picklist",
            enum: PaymentDeviceEngagement,
          },
        ],
      },
      {
        name: "Camera",
        fields: [
          {
            name: "g4b_cameraurlcontactphoto",
            description: "Camera IP or Camera Url",
            label: "Camera Url",
            type: "nvarchar",
          },
          {
            name: "g4b_camerarefreshrate",
            description: "Camera Refresh Rate",
            label: "Camera Refresh Rate",
            type: "float",
          },
        ],
      },
    ],
  },
  {
    title: "Settings",
    id: "settings",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_skipbeneficiarylevel",
            default: SkipBeneficiaryLevel.None,
            description:
              "Allows the operator to skip the assignment of defined beneficiaries when appropriate",
            label: "Skip Beneficiary Level",
            type: "picklist",
            enum: SkipBeneficiaryLevel,
          },
          {
            name: "g4b_showpromotioncriteria",
            default: false,
            description:
              "If set, then display any criteria set against a promotion",
            label: "Show Promotion Criteria",
            type: "bit",
          },
          {
            name: "g4b_sessiontimeoutminutes",
            description: "Terminal Session Timeout (Minutes)",
            label: "Session Timeout (Minutes)",
            type: "float",
          },
          {
            name: "g4b_scannedinputthreshold",
            description: "",
            label: "Scanned Input Threshold (ms)",
            type: "int",
          },
        ],
      },
    ],
  },
];
