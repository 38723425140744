import { Fragment } from "react";
import Designer from "../components/form/printTransform/Designer";
import SavedLayouts from "../components/form/printTransform/SavedLayouts";
import { PrinterJobType } from "../js/enums";
import Layout from "../components/form/printTransform/Layout";
import { applyFieldChanges } from "../components/form/printTransform/Helpers";
import {
  BarcodeFormatType,
  FieldType,
  TwoDimensionalBarcodeFormatType,
} from "../components/form/printTransform/Enums";

export const PrintTransformFormStages = [
  {
    title: "Print Transform",
    id: "print-transform",
    sections: [
      {
        name: "General",
        fields: [
          {
            name: "g4b_name",
            description: "",
            className: "col-md-6",
            isNameField: true,
            label: "Name",
            required: true,
            type: "nvarchar",
          },
          {
            name: "g4b_printerjobtypeid",
            hidden: true,
            label: "Printer Job Type",
            type: "nvarchar",
          },
          {
            name: "g4b_printerjobtypeidname",
            className: "col-md-6",
            label: "Printer Job Type",
            type: "picklist",
            enum: PrinterJobType,
            required: true,
          },
          {
            name: "g4b_singleprintjob",
            label: "Single Print Job",
            type: "bit",
          },
          {
            name: "primaryImage",
            className: "col-12",
            description: "",
            type: "image",
          },
        ],
      },
      {
        name: "Print at Home",
        fields: [
          {
            name: "g4b_ticketforegroundcolour",
            className: "col-md-6",
            description: "",
            label: "Ticket Foreground Colour",
            type: "colourpicker",
          },
          {
            name: "g4b_ticketbackgroundcolour",
            className: "col-md-6",
            description: "",
            label: "Ticket Background Colour",
            type: "colourpicker",
          },
          {
            name: "g4b_ticketlogotext",
            className: "col-12",
            description: "",
            label: "Ticket Logo Text",
            type: "nvarchar",
          },
          {
            name: "g4b_barcodeencoding",
            className: "col-md-6",
            description: "",
            enum: Object.freeze({
              ...BarcodeFormatType,
              ...TwoDimensionalBarcodeFormatType,
            }),
            label: "Barcode Encoding",
            type: "picklist",
            validate: (value) => {
              const isValid = value >= 0;
              return isValid
                ? ""
                : "You must enter a non negative whole number";
            },
          },
          {
            name: "g4b_barcodeshowlabel",
            className: "col-md-6",
            description: "",
            label: "Barcode Show Label",
            type: "bit",
          },
          {
            name: "g4b_barcodewidth",
            className: "col-md-6",
            description: "",
            label: "Barcode Width (px)",
            type: "int",
            validate: (value) => {
              const isValid = value >= 0;
              return isValid
                ? ""
                : "You must enter a non negative whole number";
            },
          },
          {
            name: "g4b_barcodeheight",
            className: "col-md-6",
            description: "",
            label: "Barcode Height (px)",
            type: "int",
            validate: (value) => {
              const isValid = value >= 0;
              return isValid
                ? ""
                : "You must enter a non negative whole number";
            },
          },
        ],
      },
    ],
  },
  {
    title: "Layout",
    id: "layout",
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4b_usexsltplus",
            autoFillValue: (state) =>
              state.g4b_printerjobtypeidname !==
              PrinterJobType["Till Report"]
                ? true
                : false,
            displayIfFromState: (state) =>
              state.g4b_printerjobtypeidname !==
              PrinterJobType["Till Report"],
            label: "Use Designer",
            type: "bit",
          },
          {
            name: "g4b_transform",
            className: "col-12",
            displayIfFromValues: (values) =>
              values.hasOwnProperty("g4b_usexsltplus")
                ? !values.g4b_usexsltplus
                : false,
            label: "Transform",
            textAreaHeight: "400px",
            type: "textarea",
          },
          {
            name: "SelectedTemplate",
            displayIfFromValues: (values) => values.g4b_usexsltplus,
            className: "col-12",
            component: SavedLayouts,
            getReadOnlyValue: (state) => {
              if (!state.SelectedTemplate) {
                return "";
              }
              return state.SelectedTemplate.Value;
            },
            required: true,
            type: "component",
          },
        ],
      },
    ],
  },
  {
    title: "Design",
    id: "design",
    displayIfFromState: (state) => state.g4b_usexsltplus && (state.back || state.front),
    formClass: "design-stage is-fixed",
    handleSubmit: (dispatch, _, values) => {
      const { editField } = values;
      if (!editField) {
        return false;
      }

      applyFieldChanges(dispatch, values);
      return true;
    },
    sections: [
      {
        name: "",
        fields: [
          {
            name: "layout",
            className: "w-100 mb-3",
            component: Designer,
            overviewRender: (state) => {
              const { back, front } = state;

              return (
                <>
                  {front && (
                    <>
                      {back && <p>Front</p>}
                      <Layout
                        allowEdit={false}
                        layoutIndex={0}
                        page={front}
                      />
                    </>
                  )}
                  {back && (
                    <>
                      <p className="mt-3">Back</p>
                      <Layout
                        allowEdit={false}
                        layoutIndex={1}
                        page={back}
                      />
                    </>
                  )}
                </>
              );
            },
            type: "component",
            validate: (_, values) => {
              // don't validate if there isn't a field being modified
              const { editField } = values;
              if (!editField) {
                return "";
              }

              const {
                BarcodeFormat,
                DateFormat,
                FontName,
                FontSize,
                ForegroundColour,
                Height,
                Left,
                Name,
                Top,
                Type,
                Width,
              } = values;
              const { Value } = values.Value;

              let errors = {};

              if (!Name) {
                errors["Name"] = "Required";
              }

              // validate position
              const left = parseInt(Left);
              if (!Number.isInteger(left) || left < 0) {
                errors["Left"] = "Required";
              }
              const top = parseInt(Top);
              if (!Number.isInteger(top) || top < 0) {
                errors["Top"] = "Required";
              }

              // validate size
              const height = parseInt(Height);
              if (!Number.isInteger(height) || height < 0) {
                errors["Height"] = "Required";
              }
              const width = parseInt(Width);
              if (!Number.isInteger(width) || width < 0) {
                errors["Width"] = "Required";
              }

              // validate values and styles depending on the type of field
              switch (Type) {
                case FieldType.Text:
                  if (!Value) {
                    errors["Value.Value"] = "Required";
                  }
                  if (!ForegroundColour) {
                    errors["ForegroundColour"] = "ForegroundColour";
                  }
                  if (!FontName) {
                    errors["FontName"] = "Required";
                  }
                  if (!FontSize) {
                    errors["FontSize"] = "Required";
                  }
                  break;
                case FieldType.Image:
                  if (!Value) {
                    errors["Value.Value"] = "Required";
                  }
                  break;
                case FieldType.LinearBarcode:
                case FieldType.TwoDimensionalBarcode:
                  if (!BarcodeFormat) {
                    errors["BarcodeFormat"] = "Required";
                  }
                  break;
                case FieldType.Date:
                  if (!Value) {
                    errors["Value.Value"] = "Required";
                  }
                  if (!DateFormat) {
                    errors["DateFormat"] = "Required";
                  }
                  if (!ForegroundColour) {
                    errors["ForegroundColour"] = "ForegroundColour";
                  }
                  if (!FontName) {
                    errors["FontName"] = "Required";
                  }
                  if (!FontSize) {
                    errors["FontSize"] = "Required";
                  }
                  break;
                default:
                  break;
              }
              return Object.keys(errors).length > 0 ? errors : "";
            },
          },
          {
            name: "front",
            hidden: true,
            type: "nvarchar",
          },
          {
            name: "back",
            hidden: true,
            type: "nvarchar",
          },
        ],
        render: (formFields, index) => {
          return <Fragment key={index}>{formFields}</Fragment>;
        },
      },
    ],
    showButtonSection: (values) => !values.editControl,
  },
  {
    title: "Save layout",
    id: "save-layout",
    displayIfFromState: (state) => state.g4b_usexsltplus,
    editOnOverview: true,
    renderStage: false,
    sections: [
      {
        name: "",
        fields: [
          {
            name: "g4pd_islayout",
            className: "col-12",
            label: "Save as layout",
            type: "bit",
          },
          {
            name: "g4pd_layoutname",
            displayIfFromValues: (values) => values.g4pd_islayout,
            label: "Layout name",
            required: true,
            type: "nvarchar",
          },
        ],
      },
    ],
  },
];
