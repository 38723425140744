import { PastYearsSelectHeader } from "../components/elements/DateSelectHeader";
import { CommissionCategoryFormStages } from "./commissionCategory";
import lookups from "./lookups";

export const CommissionCategoriesFormStages = [
  {
    title: "Commission Categories",
    sections: [
      {
        name: "",
        table: {
          allowAdd: true,
          allowEdit: true,
          allowStatusEdit: true,
          fields: [
            {
              name: "createdon",
              className: "col-date",
              type: "datetime",
            },
            {
              name: "modifiedon",
              className: "col-date",
              type: "datetime",
            },
          ],
          filterByStatus: true,
          filters: [
            {
              textFieldsSearch: ["Name"],
              type: "text",
            },
            {
              name: "createdon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
            {
              name: "modifiedon",
              renderCustomHeader: PastYearsSelectHeader,
              type: "datetime",
            },
          ],
          linkForNewRecord: "/commission-category",
          lookup: lookups.g4b_commissioncategory.all,
          subFormStages: CommissionCategoryFormStages,
          useAlphabetPagination: true,
          useSubForm: false,
        },
      },
    ],
  },
];
