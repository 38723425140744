import { Alert } from "./components/elements/_Elements";
import { AlertStyle } from "./js/enums";

export default function InvalidRole() {
  // update the page title
  document.title = "VisiT - Invalid Role";

  return (
    <Alert
      className="m-3"
      style={AlertStyle.Danger}
      text={"Invalid Role"}
    />
  );
}
