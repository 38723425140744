import { Link } from "react-router-dom";

function EditLink({
  id,
  linkForNewRecord,
  openNewTab = false,
  text,
}) {
  return (
    <Link
      to={`${linkForNewRecord}?id=${id}`}
      target={openNewTab ? "_blank" : ""}
      rel={openNewTab ? "noopener noreferrer" : ""}
      state={{
        canGoBack: true,
        title: text,
      }}
    >
      {text}
    </Link>
  );
}

export default EditLink;
