import React, { useEffect, useState } from "react";
import { useGlobalReducer } from "../../../GlobalContext";
import { GetCommunicationStatistics } from "../../../js/service";
import { Alert, Button, Loading } from "../../elements/_Elements";
import {
  AlertStyle,
  ButtonStyle,
  LoadingState,
} from "../../../js/enums";
import CommunicationStatisticsValues from "./CommunicationStatisticsValues";

function CommunicationStatistics({ state }) {
  const globalDispatch = useGlobalReducer();

  const [loading, setLoading] = useState(LoadingState.NotLoaded);
  const [formData, setFormData] = useState(null);

  const { id } = state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [serviceResponse] = await Promise.all([
          GetCommunicationStatistics(globalDispatch, id, false),
        ]);
        if (serviceResponse && serviceResponse.data) {
          setFormData(serviceResponse.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(LoadingState.Loaded);
      }
    };
    if (loading === LoadingState.NotLoaded && id) {
      setLoading(LoadingState.Loading);
      fetchData();
    }
  }, [globalDispatch, id, loading]);

  if (loading !== LoadingState.Loaded) {
    return <Loading />;
  }

  const { ErrorMessage } = formData;

  if (ErrorMessage) {
    return <Alert style={AlertStyle.Danger} text={ErrorMessage} />;
  }

  return (
    <>
      {!state.g4c_active && (
        <Alert
          style={AlertStyle.Danger}
          text={" This communication has been stopped."}
        />
      )}
      <div className="row">
        <div className="col-12">
          <Button
            className="mb-3 float-end"
            onClick={() => setLoading(LoadingState.NotLoaded)}
            style={ButtonStyle.Link}
            text="Refresh"
          />
        </div>
        <CommunicationStatisticsValues
          formData={formData}
          state={state}
        />
      </div>
    </>
  );
}

export default CommunicationStatistics;
