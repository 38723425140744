import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import { withFormik } from "formik";
import { DispatchMethods } from "./enums";
import {
  Alert,
  Card,
  FormPage,
  Loading,
  SubmitFormPage,
} from "../elements/_Elements";
import FormFields from "./FormFields";
import FormTable from "./FormTable";
import SubForm from "./SubForm";
import {
  checkIfFieldDisabled,
  checkIfFieldRequired,
  checkIfRequiredFieldValueValid,
  createOrUpdateRecord,
  displayElement,
  getCustomAction,
  getHeaderText,
  getLookupsForStages,
  getNameFieldValue,
  getOverviewIndex,
  getSectionInitialFilters,
  handleCheckListChange,
  handleFilteredPicklistChange,
  handlePicklistChange,
  handleRedirect,
  mapPropsToValuesForStages,
  scrollToTopOfPage,
  useLookupOptions,
} from "./Helpers";
import { AlertStyle, LoadingState } from "../../js/enums";
import FormTableSubmit from "./FormTableSubmit";
import { useGlobalState } from "../../GlobalContext";

function FormSection({
  auditLabel,
  cancelSubForm,
  closeRedirect,
  customCopyComponent,
  dispatch,
  entityName,
  errors,
  filteredStages,
  globalDispatch,
  handleBlur,
  handleChange,
  handleSubmit,
  headerComponent,
  informationComponent,
  isSubForm,
  isSubmitting,
  onSubmitSubForm,
  parentFormEntityName,
  parentFormState,
  setFieldValue,
  showCopyButton = false,
  showInfo,
  showSaveAndCloseButton = true,
  showSaveAndNewButton = false,
  stageIndex,
  stages,
  state,
  touched,
  values,
  saveAndNewRedirect,
}) {
  const [loading, setLoading] = useState(LoadingState.NotLoaded);
  const [subForm, setSubForm] = useState("");
  const [customAction, setCustomAction] = useState("");

  const navigate = useNavigate();
  const location = useLocation();

  //When going from one stage to another, the loading state may still
  //set to LoadingState.Loaded when it shouldn't be. This causes lookups
  //in the new stage to not be loaded in useLookupOptions because of this
  //loading state value. This will make sure to reset the loading state back
  //to LoadingState.NotLoaded
  useEffect(() => {
    setLoading(LoadingState.NotLoaded);
  }, [stageIndex]);

  const onBack = () => {
    if (location.state?.canGoBack) {
      navigate(-1);
    } else {
      dispatch({
        type: DispatchMethods.SetRedirect,
        redirect: closeRedirect ?? "/bookings", // fallback
      });
    }
  };

  const stage = stages[stageIndex - 1];
  const FormComponent = stages.length > 1 ? FormPage : SubmitFormPage;
  const globalState = useGlobalState();

  const { displayAlert, formClass, sections, title } = stage;

  const filteredSections = sections.filter((section) =>
    displayElement(section, state, values, globalState)
  );

  const fieldSections = filteredSections.filter(
    (section) =>
      section.fields &&
      section.fields.length > 0 &&
      section.fields.filter((field) =>
        displayElement(field, state, values, globalState)
      ).length > 0
  );

  //For any section that we display, check through to see if there exists one thats not disabled
  const anyEnabledFields = fieldSections.some(
    (section) =>
      section.fields.filter(
        (field) =>
          displayElement(field, state, values, globalState) &&
          !checkIfFieldDisabled(field, state, values)
      ).length > 0
  );

  const tableSections = filteredSections.filter(
    (section) =>
      section.table &&
      displayElement(section.table, state, values, globalState)
  );
  const lookupOptions = getLookupsForStages([stage], state);
  const overviewIndex = getOverviewIndex(stages);
  const displayAlertText = displayAlert ? displayAlert(state) : "";
  // use the name in state if there is only a single stage and the record has been created
  const header = getHeaderText(
    entityName,
    stages,
    state,
    title,
    false
  );

  useLookupOptions(
    dispatch,
    globalDispatch,
    loading,
    lookupOptions,
    setLoading,
    state
  );

  if (loading !== LoadingState.Loaded) {
    <Loading />;
  }

  if (displayAlertText) {
    return (
      <Alert style={AlertStyle.Danger} text={displayAlertText} />
    );
  }

  return (
    <>
      {subForm && (
        <SubForm
          {...{
            dispatch,
            entityName,
            errors,
            setSubForm,
            state,
            subForm,
            values,
          }}
        />
      )}
      {customAction &&
        getCustomAction(
          customAction,
          setCustomAction,
          setFieldValue,
          state,
          values
        )}
      <FormComponent
        {...{
          auditLabel,
          dispatch,
          entityName,
          errors,
          filteredStages,
          headerComponent,
          informationComponent,
          loading,
          onSubmitSubForm,
          setLoading,
          showInfo,
          stage,
          state,
          values,
        }}
        backText={isSubForm ? "Cancel" : "Back"}
        className={classNames(formClass, subForm ? "d-none" : "")}
        customCopyComponent={customCopyComponent}
        disabled={subForm !== "" || customAction !== ""}
        header={header}
        isBusy={isSubmitting}
        loading={loading}
        onBack={
          isSubForm && cancelSubForm
            ? () => cancelSubForm()
            : stageIndex === overviewIndex && fieldSections.length > 0
            ? () => {
                scrollToTopOfPage();
                onBack();
              }
            : state.skipToOverview || stageIndex > 1
            ? () => {
                scrollToTopOfPage();
                dispatch({
                  type: DispatchMethods.SetPreviousStage,
                  stageIndex: stageIndex,
                  stages: stages,
                });
              }
            : null
        }
        onSubmit={handleSubmit}
        saveAndCloseSubmitButtonClickEvent={() =>
          setFieldValue("action", "saveAndClose")
        }
        saveAndNewSubmitButtonClickEvent={() =>
          setFieldValue("action", "saveAndNew")
        }
        setFieldValue={setFieldValue}
        showButtonSection={
          typeof stage.showButtonSection !== "undefined"
            ? stage.showButtonSection(values)
            : true
        }
        showCopyButton={
          showCopyButton && state.id && fieldSections.length > 0
            ? true
            : false
        }
        showSaveAndCloseButton={
          showSaveAndCloseButton &&
          fieldSections.length > 0 &&
          anyEnabledFields
            ? true
            : false
        }
        showSaveAndNewButton={
          showSaveAndNewButton &&
          fieldSections.length > 0 &&
          anyEnabledFields
            ? true
            : false
        }
        showSaveButton={
          isSubForm || fieldSections.length === 0 || !anyEnabledFields
            ? false
            : true
        }
        submitText={
          state.skipToOverview
            ? "Done"
            : stages.length > 1
            ? "Next"
            : "Save"
        }
        submitButtonClickEvent={() => setFieldValue("action", "save")}
        saveAndNewRedirect={saveAndNewRedirect}
        stages={stages}
      >
        {fieldSections.map((section, i) => {
          const formFields = (
            <FormFields
              disabled={subForm !== "" || customAction !== ""}
              {...{
                dispatch,
                entityName,
                errors,
                handleBlur,
                handleChange,
                handleCheckListChange,
                handleFilteredPicklistChange,
                handlePicklistChange,
                parentFormState,
                section,
                setCustomAction,
                setFieldValue,
                setSubForm,
                stages,
                state,
                touched,
                values,
              }}
            />
          );

          return section.render ? (
            section.render(formFields, i)
          ) : (
            <Card key={i} className="mb-3">
              <div className="row g-3">{formFields}</div>
            </Card>
          );
        })}
        {tableSections.map((section, i) => {
          const initialFilters = getSectionInitialFilters(
            section,
            state.id
          );
          // Don't show the table if the record hasn't been created yet
          // but display a message indicating that finishing the creation of the
          // new record would make it appear. If entityName is undefined this indicates
          // that we are on a page where its just the list of records in a table such as
          // /products and /communications
          if (initialFilters.length > 0 && entityName && !state.id) {
            return (
              <Fragment key={i}>
                {section.name && <h5>{section.name}</h5>}
                <div className="mb-3">
                  <strong>
                    Please save the current record to view the table
                    for this section
                  </strong>
                </div>
              </Fragment>
            );
          }

          const TableComponent =
            section.table && section.table.useSubmitButton
              ? FormTableSubmit
              : FormTable;

          return (
            <Card key={i} className="mb-3">
              <TableComponent
                {...{
                  dispatch,
                  section,
                  setSubForm,
                  state,
                  subForm,
                  values,
                }}
                disabled={subForm !== "" || customAction !== ""}
                initialFilters={initialFilters}
                parentId={state.id}
                subFormParentFormEntityName={parentFormEntityName}
                subFormParentFormState={parentFormState}
              />
            </Card>
          );
        })}
      </FormComponent>
    </>
  );
}

const FormSectionWithFormik = withFormik({
  enableReinitialize: true,

  mapPropsToValues: (props) => {
    const {
      linkedEntities,
      parentFormEntityName,
      parentFormFieldLookup,
      parentFormState,
      parentFormValues,
      stageIndex,
      stages,
      state,
      saveAndNewRedirect,
    } = props;
    if (state && state.reloadValues) {
      return state.reloadValues;
    }
    return mapPropsToValuesForStages(
      [stages[stageIndex - 1]],
      state,
      parentFormEntityName,
      parentFormFieldLookup,
      parentFormState,
      parentFormValues,
      linkedEntities,
      saveAndNewRedirect
    );
  },

  validate: (values, props) => {
    const errors = {};

    const stage = props.stages[props.stageIndex - 1];
    stage.sections
      .filter((section) => section.fields)
      .forEach((section) => {
        section.fields.forEach((field) => {
          if (
            checkIfFieldRequired(
              field,
              props.state,
              values,
              props.globalState
            ) &&
            !checkIfRequiredFieldValueValid(field, values)
          ) {
            errors[field.name] = "Required";
          } else if (field.validate) {
            const error = field.validate(
              values[field.name],
              values,
              props.state
            );
            if (
              error &&
              (!Array.isArray(error) || error.length > 0)
            ) {
              errors[field.name] = error;
            }
          }
        });
      });

    return errors;
  },

  handleSubmit: async (values, { props }) => {
    const {
      closeRedirect,
      dispatch,
      entityName,
      globalDispatch,
      methodName,
      refreshPageUponSave,
      saveAndNewRedirect,
      stageIndex,
      stages,
      state,
      onSubmitSubForm,
    } = props;

    // Remove any values with property "action"
    const { action, ...submitValues } = values;

    const stage = stages[stageIndex - 1];

    const newValues = stage.mapValuesOnSubmit
      ? await stage.mapValuesOnSubmit(dispatch, state, submitValues)
      : { ...submitValues };

    // allow a stage to have custom submit behaviour
    if (stage.handleSubmit) {
      const stageResult = stage.handleSubmit(
        dispatch,
        state,
        submitValues
      );
      // if the result is true, then return and don't execute the default behaviour
      if (stageResult) {
        return;
      }
    }

    if (stages.length !== 1) {
      // update the record in the context
      props.dispatch({
        type: DispatchMethods.UpdateRecordAndGoToNextStage,
        values: newValues,
        stageIndex: stageIndex,
        stages: props.stages,
      });

      // scroll to the top of the page before going onto the next section
      scrollToTopOfPage();
      return;
    }

    const updatedState = { ...state, ...newValues };

    const id = await createOrUpdateRecord(
      action,
      entityName,
      globalDispatch,
      stages,
      updatedState,
      methodName
    );

    if (id) {
      const { name } = getNameFieldValue(
        stages,
        updatedState,
        entityName
      );
      if (name !== state.name) {
        props.dispatch({
          type: DispatchMethods.SetName,
          name: name,
        });
      }

      if (onSubmitSubForm) {
        onSubmitSubForm(id, name, submitValues);
      } else if (action) {
        handleRedirect(
          action === "saveAndNew"
            ? saveAndNewRedirect
            : closeRedirect,
          dispatch,
          id,
          action === "save" && refreshPageUponSave
            ? "refreshPage"
            : action,
          updatedState
        );
      }
    }
  },
})(FormSection);

export default FormSectionWithFormik;
