import React from "react";
import { Alert } from "../../elements/_Elements";
import { AlertStyle } from "../../../js/enums";

function CommunicationABTestPublishMessage() {
  return (
    <Alert
      className="my-3"
      style={AlertStyle.Warning}
      text={
        "This communication is an A/B Testing communication and must be published from the A/B Test. The expiry URL, start and end dates are also set from within the A/B test record."
      }
    />
  );
}

export default CommunicationABTestPublishMessage;
