import DefaultLayout from "./layouts/DefaultLayout";
import { FormProvider } from "./components/form/FormContext";
import Form from "./components/form/Form";
import { useGlobalState } from "./GlobalContext";
import { userHasCorrectRole } from "./js/roles";
import InvalidRole from "./InvalidRole";

export default function FormPage({
  allowedRoles,
  auditLabel,
  closeRedirect,
  customCopyComponent,
  entityName,
  headerComponent,
  hideFormWhileSubmitting,
  informationComponent,
  loadDataMethod,
  methodName,
  refreshPageUponSave,
  renderSidebar,
  saveAndCloseRedirect,
  saveAndNewRedirect,
  showCopyButton,
  showInfo,
  showSaveAndCloseButton,
  showSaveButton,
  showSaveAndNewButton,
  stages,
  title,
}) {
  const globalState = useGlobalState();
  const { user } = globalState;

  const hasCorrectRole = userHasCorrectRole(user, allowedRoles);

  return (
    <DefaultLayout {...{ renderSidebar }}>
      {user && (
        <FormProvider>
          {!hasCorrectRole ? (
            <InvalidRole />
          ) : (
            <Form
              {...{
                auditLabel,
                closeRedirect,
                customCopyComponent,
                entityName,
                headerComponent,
                hideFormWhileSubmitting,
                informationComponent,
                loadDataMethod,
                methodName,
                refreshPageUponSave,
                saveAndCloseRedirect,
                saveAndNewRedirect,
                showCopyButton,
                showInfo,
                showSaveAndCloseButton,
                showSaveButton,
                showSaveAndNewButton,
                stages,
                title,
              }}
            />
          )}
        </FormProvider>
      )}
    </DefaultLayout>
  );
}
