import { useState } from "react";
import { Tabs } from "../../elements/_Elements";
import SidebarForm from "../SidebarForm";
import GetSidebarFormStages from "./SidebarFormStages";

function SidebarTabs({
  handleChange,
  handlePicklistChange,
  setFieldValue,
  state,
  values,
}) {
  const stages = GetSidebarFormStages(values);

  const [activeTab, setActiveTab] = useState(stages[0].title);

  const handleChangeValue = (event) => {
    // if (!isEditingControl && saveUndoSnapshot) {
    //   saveUndoSnapshot(values);
    // }
    handleChange(event);
  };

  const handlePicklistChangeValue = (event) => {
    // if (!isEditingControl && saveUndoSnapshot) {
    //   saveUndoSnapshot(values);
    // }
    handlePicklistChange(event);
  };

  return (
    <>
      <Tabs
        activeTab={activeTab}
        onClick={(tab) => setActiveTab(tab.title)}
        tabs={stages}
      />
      <div className="sidebar-content position-relative flex-fill my-3">
        <SidebarForm
          {...{
            activeTab,
            setFieldValue,
            state,
            values,
          }}
          handleChange={handleChangeValue}
          handlePicklistChange={handlePicklistChangeValue}
          stages={stages}
          useTabs={true}
        />
      </div>
    </>
  );
}

export default SidebarTabs;
