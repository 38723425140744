import React, { useEffect, useState } from "react";
import { useGlobalReducer } from "../../../GlobalContext";
import {
  GetCommunicationStatistics,
  Update,
} from "../../../js/service";
import {
  Alert,
  Button,
  Loading,
  Modal,
} from "../../elements/_Elements";
import {
  AlertStyle,
  ButtonStyle,
  LoadingState,
} from "../../../js/enums";
import CommunicationStatisticsValues from "../communication/CommunicationStatisticsValues";
import {
  getCommClickRatePercentageText,
  getCommOpenRatePercentageText,
  getCommRevenueValueText,
} from "./Helpers";
import { handleRedirect } from "../Helpers";
import { useFormReducer } from "../FormContext";

function ABTestStatistics({ state }) {
  const globalDispatch = useGlobalReducer();
  const dispatch = useFormReducer();

  const [loading, setLoading] = useState(LoadingState.NotLoaded);
  const [commAData, setCommAData] = useState(null);
  const [commBData, setCommBData] = useState(null);

  const [commStatsModal, setCommStatsModal] = useState(null);
  const [commConfirmWinner, setCommConfirmWinner] = useState(null);
  const [selectedComm, setSelectedComm] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(null);

  const { id } = state;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [commAResponse, commBResponse] = await Promise.all([
          GetCommunicationStatistics(
            globalDispatch,
            state.g4c_communicationaid,
            false
          ),
          GetCommunicationStatistics(
            globalDispatch,
            state.g4c_communicationbid,
            false
          ),
        ]);
        if (commAResponse && commAResponse.data) {
          setCommAData(commAResponse.data);
        }
        if (commBResponse && commBResponse.data) {
          setCommBData(commBResponse.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(LoadingState.Loaded);
      }
    };
    if (loading === LoadingState.NotLoaded && id) {
      setLoading(LoadingState.Loading);
      fetchData();
    }
  }, [
    globalDispatch,
    id,
    loading,
    state.g4c_communicationaid,
    state.g4c_communicationbid,
  ]);

  const selectWinner = async (selectedCommId, selectedCommName) => {
    setIsSubmitting(true);
    try {
      if (
        selectedCommId === state.g4c_communicationaid ||
        selectedCommId === state.g4c_communicationbid
      ) {
        let attributes = {
          selectedcommid: selectedCommId,
          g4c_winningcommunication:
            selectedCommId === state.g4c_communicationaid ? 0 : 1,
        };
        const successMessage =
          selectedCommName + " selected as winner";
        const [serviceResponse] = await Promise.all([
          Update(
            globalDispatch,
            successMessage,
            {
              Id: state.id,
              Name: state.name,
              Attributes: attributes,
            },
            "g4c_abtest",
            "email-marketing/selectabtestwinner"
          ),
        ]);
        if (serviceResponse && serviceResponse.data) {
          handleRedirect(null, dispatch, state.id, "refreshPage");
        }
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
    }
  };

  if (loading !== LoadingState.Loaded) {
    return <Loading />;
  }

  if (commAData.ErrorMessage) {
    return (
      <Alert
        style={AlertStyle.Danger}
        text={commAData.ErrorMessage}
      />
    );
  }

  if (commBData.ErrorMessage) {
    return (
      <Alert
        style={AlertStyle.Danger}
        text={commBData.ErrorMessage}
      />
    );
  }

  const displayRevenueColumn =
    (commAData.TicketingInstalled && commAData.DefaultAttribution) ||
    (commBData.TicketingInstalled && commBData.DefaultAttribution);

  return (
    <>
      {commConfirmWinner && (
        <Modal
          title={"Select Winning Communication"}
          modalCloseButtonClick={() => {
            setSelectedComm(null);
            setCommConfirmWinner(null);
          }}
          className="modal modal-dialog-scrollable"
        >
          <div className="modal-body">
            <p>
              Do you want to select{" "}
              <strong>{commConfirmWinner.CommunicationName}</strong>{" "}
              as the winner?
            </p>
          </div>
          <div className="modal-footer">
            <Button
              text={"Yes"}
              style={ButtonStyle.Primary}
              onClick={() =>
                selectWinner(
                  selectedComm,
                  commConfirmWinner.CommunicationName
                )
              }
              disabled={isSubmitting}
              showSpinner={isSubmitting}
            />
            <Button
              text={"No"}
              style={ButtonStyle.Info}
              onClick={() => {
                setSelectedComm(null);
                setCommConfirmWinner(null);
              }}
              disabled={isSubmitting}
              showSpinner={isSubmitting}
            />
          </div>
        </Modal>
      )}
      {commStatsModal && (
        <>
          <Modal
            title={commStatsModal.CommunicationName}
            modalCloseButtonClick={() => {
              setSelectedComm(null);
              setCommStatsModal(null);
            }}
            className="modal modal-dialog-scrollable modal-fullscreen"
          >
            <div className="modal-body">
              <CommunicationStatisticsValues
                formData={commStatsModal}
              />
            </div>
            <div className="modal-footer">
              <Button
                text={"Close"}
                style={ButtonStyle.Info}
                onClick={() => {
                  setSelectedComm(null);
                  setCommStatsModal(null);
                }}
              />
            </div>
          </Modal>
        </>
      )}
      {state.g4c_winningcommunication !== 0 &&
        state.g4c_winningcommunication !== 1 && (
          <div className="row">
            <h5 className="col-12">
              <strong>Select Winner</strong>
            </h5>
            <div className="col-md-4">
              <Button
                className="mb-3 me-3"
                onClick={() => {
                  setSelectedComm(state.g4c_communicationaid);
                  setCommConfirmWinner(commAData);
                }}
                style={ButtonStyle.Primary}
                text={commAData.CommunicationName}
                disabled={isSubmitting}
                showSpinner={isSubmitting}
              />
            </div>
            <div className="col-md-4">
              <Button
                className="mb-3"
                onClick={() => {
                  setSelectedComm(state.g4c_communicationbid);
                  setCommConfirmWinner(commBData);
                }}
                style={ButtonStyle.Primary}
                text={commBData.CommunicationName}
                disabled={isSubmitting}
                showSpinner={isSubmitting}
              />
            </div>
          </div>
        )}
      <div className="row">
        <div className="col-12">
          <Button
            className="mb-3 float-end"
            onClick={() => setLoading(LoadingState.NotLoaded)}
            style={ButtonStyle.Link}
            text="Refresh"
          />
        </div>
        <div className="list-view">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr className="table-info">
                  <th scope="col" className={"col-short-name"}>
                    Name
                  </th>
                  <th scope="col" className={"col-name"}>
                    Open Rate
                  </th>
                  <th scope="col" className={"col-name"}>
                    Click Rate
                  </th>
                  {displayRevenueColumn && (
                    <th scope="col" className={"col-name"}>
                      Revenue
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                <tr
                  className={
                    state && state.g4c_winningcommunication === 0
                      ? "table-success"
                      : ""
                  }
                  onClick={() => {
                    setSelectedComm(state.g4c_communicationaid);
                    setCommStatsModal(commAData);
                  }}
                >
                  <th scope="row">{`${commAData.CommunicationName} ${
                    state && state.g4c_winningcommunication === 0
                      ? " - WINNER!"
                      : ""
                  }`}</th>
                  <td>
                    {getCommOpenRatePercentageText(
                      commAData.HardBounces,
                      commAData.SoftBounces,
                      commAData.Statistics
                    )}
                  </td>
                  <td>
                    {getCommClickRatePercentageText(
                      commAData.HardBounces,
                      commAData.SoftBounces,
                      commAData.Statistics,
                      commAData.UniqueClicks
                    )}
                  </td>
                  {displayRevenueColumn && (
                    <td>
                      {getCommRevenueValueText(
                        commAData.DefaultAttribution
                      )}
                    </td>
                  )}
                </tr>
                <tr
                  className={
                    state && state.g4c_winningcommunication === 1
                      ? "table-success"
                      : ""
                  }
                  onClick={() => {
                    setSelectedComm(state.g4c_communicationbid);
                    setCommStatsModal(commBData);
                  }}
                >
                  <th scope="row">{`${commBData.CommunicationName} ${
                    state && state.g4c_winningcommunication === 1
                      ? " - WINNER!"
                      : ""
                  }`}</th>
                  <td>
                    {getCommOpenRatePercentageText(
                      commBData.HardBounces,
                      commBData.SoftBounces,
                      commBData.Statistics
                    )}
                  </td>
                  <td>
                    {getCommClickRatePercentageText(
                      commBData.HardBounces,
                      commBData.SoftBounces,
                      commBData.Statistics,
                      commBData.UniqueClicks
                    )}
                  </td>
                  {displayRevenueColumn && (
                    <td>
                      {getCommRevenueValueText(
                        commBData.DefaultAttribution
                      )}
                    </td>
                  )}
                </tr>
              </tbody>
            </table>
          </div>
          <p>Click on a communication to view detailed statistics</p>
        </div>
      </div>
    </>
  );
}

export default ABTestStatistics;
