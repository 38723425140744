import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDown, ChevronUp } from "../Icons";

function CollapsibleSection({
  allowCollapse = true,
  children,
  initialCollapsed = false,
  id,
  sectionControls,
  title,
}) {
  const [collapsed, setCollapsed] = useState(initialCollapsed);
  return (
    <>
      <div className="d-flex justify-content-between">
        <h4 className="fw-bold">{title}</h4>
        <div>
          {sectionControls}
          {allowCollapse && (
            <button
              className="btn btn-sm btn-info rounded-circle ms-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#${id}-collapse-component`}
              aria-expanded={initialCollapsed ? "false" : "true"}
              aria-controls="collapse-component"
              onClick={() => setCollapsed(!collapsed)}
            >
              <FontAwesomeIcon
                icon={collapsed ? ChevronDown : ChevronUp}
              />
            </button>
          )}
        </div>
      </div>
      <div
        className={initialCollapsed ? "collapse" : "show"}
        id={`${id}-collapse-component`}
      >
        {children}
      </div>
    </>
  );
}

export default CollapsibleSection;
